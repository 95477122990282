import {
  post,
  get,
  patch,
  del
} from '@/assets/queries'
import { getVoltageArray } from './resultsFunctions'
import { DTCHANGES_EMPTY } from '@/store/constants'

function addOrInitialize (idx, key, objFrom, objTo) {
  const modObjTo = objTo
  // const devData = objFrom[key];
  if (key in modObjTo) {
    // Case the entry was already created
    Object.keys(objFrom[key]).forEach((k) => {
      modObjTo[key][k].push(objFrom[key][k][idx])
    })
  } else {
    modObjTo[key] = {}
    // Case the object needs to be initialized
    Object.keys(objFrom[key]).forEach((k) => {
      modObjTo[key][k] = [objFrom[key][k][idx]]
    })
  }
  return modObjTo
}

class DigitalTwin {
  constructor (DB, Results, projectName, host, startTime, sqlPromise, warnFunc, errorFunc,
    infoFunc) {
    /*
    Class that stores the results of a digital twin simulation.
    It will be used for two purposes: access results and create incremental cases.
    */
    this.infoFunc = infoFunc
    this.errorFunc = errorFunc
    this.warnFunc = warnFunc
    this.projectName = projectName
    this.host = host
    this.sqlPromise = sqlPromise
    this.DB = DB
    this.Results = Results
    this.caseNum = 1
    this.cases = {
      base: {
        idx: 0,
        mod: DTCHANGES_EMPTY()
      }
    }
    this.startTime = startTime
    this.ws_protocol = (host.split('://')[0] === 'https') ? 'wss' : 'ws'
  }

  /* method use when before simulating a newly created case.
  Store it in backend database before simulating it */
  addCase (caseName, referenceCase, modJson) {
    this.cases[caseName] = {
      idx: this.caseNum,
      mod: modJson
    }
    this.caseNum += 1
    // POST data to backend
    const myHeaders = new Headers()
    myHeaders.append('Content-Type', 'application/json')
    const body = JSON.stringify(modJson)
    return post(`${this.host}/digital-twin/case?project=${this.projectName}&name=${caseName}&case=${referenceCase}`, myHeaders, body)
  }

  deleteCase (caseName) {
    const caseIndex = this.cases[caseName].idx
    // DELETE ENTRY IN THE FRONTEND OBJECT
    delete this.cases[caseName]
    // Reduce the case number parameter
    this.caseNum -= 1
    // Delete results array
    this.Results.BufferList.splice(caseIndex, 1)
    this.Results.BufferCount -= 1
    // Update indices of cases
    Object.keys(this.cases).forEach((k) => {
      if (this.cases[k].idx > caseIndex) {
        this.cases[k].idx -= 1
      }
    })
    // DELETE CASE IN THE BACKEND
    return del(`${this.host}/digital-twin/case?project=${this.projectName}&case=${caseName}`)
  }

  deleteProject () {
    return del(`${this.host}/digital-twin/project?name=${this.projectName}`)
  }

  simulateCase (caseName, previousCase) {
    return new Promise((resolve) => {
      function resolveDecorator (wrapped) {
        return async function decoratedFunction (...args) {
          const localResult = await wrapped.apply(this, args)
          resolve(this)
          return localResult
        }
      }

      this.downloadResults = resolveDecorator(this.downloadResults)
      this.createDTSocketCase(caseName, previousCase)
    })
  }

  getCaseInfo (caseName) {
    return get(`${this.host}/digital-twin/case?project=${this.projectName}&case=${caseName}`)
      .then((response) => {
        this.cases[caseName].mod = response.data
      })
  }

  createDTSocketCase (caseName, previousCase) {
    // TODO: create a general WebSockets object in order to avoid the duplication of the
    //       code below in the DigitalTwinCreator and DigitalTwin classes.
    /*
        Function to create the WebSocket to stablish connection to the backend digital twin.
        Returns a WebSocket object.
        Params:
            * projectName (string): name of the case
            * networks (array[uint]): list of networks names to simulate
            * t0 (int): id of the initial instant of simulation
            * t1 (int): id of end instant to simulate.
        */
    // Instantiation of the WebSocket connection
    const DTSocket = new WebSocket(
      `${this.ws_protocol}://${
        this.host.split('/')[2]
      }/digital-twin-ws/run/`
    )
    // When the connection is established, send the parameters
    // for the DigitalTwin set-up.
    DTSocket.onopen = async () => {
      this.infoFunc('Established connection to Digital Twin')
      DTSocket.send(JSON.stringify({
        project: this.projectName,
        case: caseName,
        user_id: sessionStorage.user_id
      }))
    }

    // Function that interprets the messages coming from the backend
    DTSocket.onmessage = async (e) => {
      if ((typeof e.data) === 'string') { // Case when messages are text
        const message = JSON.parse(e.data)
        const { Type, data } = message
        if (Type === 'info') {
          this.infoFunc(data.message) // Information messages coming from the backend
        } else if (Type === 'warning') {
          this.warnFunc(data.message) // Warning messages coming from the backend
        } else if (Type === 'error') {
          this.errorFunc(data.message) // Error messages coming from the backend
        }
      } else { // Case when messages are binary (results)
        this.warnFunc('No binary messages expected!!')
      }
    }

    DTSocket.onclose = async (e) => {
      if (e.code === 1000) {
        this.infoFunc('Simulation finished')
        await this.downloadResults(caseName, previousCase)
      } else {
        this.errorFunc(`Connection closed with code ${e.code}`)
      }
    }

    return DTSocket
  }

  async downloadResults (caseName, previousCase) {
    /*
    Callback function when a new case was resolved.
    The frame buffer is downloaded and added to the results buffer.
    */
    // Check if caseName already in object
    if (caseName in this.cases) {
      return getVoltageArray(this.host, this.projectName, caseName)
        .then(async (response) => {
          this.prepareTopology(caseName, previousCase)
          // Add Voltage results to Buffer
          this.Results.BufferCount += 1
          this.Results.BufferList[this.cases[caseName].idx] = response
        })
        .catch((err) => this.errorFunc(err))
    } // ELSE
    console.error('Needed to initialize case before reading results.')
    return {}
  }

  async prepareTopology (caseName, previousCase) {
    /* Always executing when a new creating or switching between cases */
    await this.updateDB(caseName, previousCase, this.DB)
    await this.updateResults(this.DB)
  }

  getModCGPs (caseName) {
    // Method to get ids of ALL CGPS modified
    // RESPECT TO BASE
    const cgpList = []
    const mods = this.cases[caseName].mod
    Object.keys(mods).forEach((key) => {
      if (key !== 'Switch' && key !== 'LoadScale' && key !== 'CGP_New' && key !== 'NewBusCount') {
        mods[key].CGP.forEach((cgp) => {
          if (cgpList.indexOf(cgp) === -1) {
            cgpList.push(cgp)
          }
        })
      }
    })
    return cgpList
  }

  /**
   * This function calls another in PFR to solve a problem with ESS when you change cases (The number of buses changes)
   */
  adjustBusCount (NewBusCount) {
    this.Results.AdjustBusCount(NewBusCount)
  }

  getModSwitches (caseName) {
    // Method to get id of ALL SWITCHES
    // MODIFIED IN THE CASENAME RESPECT TO BASE
    const mods = this.cases[caseName].mod
    if ('Switch' in mods) {
      return mods.Switch
    }
    return []
  }

  getChanges (cgpId, caseName) {
    let devs = {}
    const mods = this.cases[caseName].mod
    Object.keys(mods).forEach((key) => {
      if (key !== 'Switch' && key !== 'LoadScale' && key !== 'CGP_New' && key !== 'NewBusCount') {
        mods[key].CGP.forEach((cgp, id) => {
          if (cgp === cgpId) {
            devs = addOrInitialize(id, key, mods, devs)
          }
        })
      }
    })
    return devs
  }

  getMeters (cgpId, caseName) {
    // Get the information from the database (base case)
    const results = this.DB.exec(`SELECT ID as id, Phase1 as phase, Name as name FROM Meter WHERE CGP1==${cgpId};`)
    if (results.length === 0) {
      return []
    }
    let baseInfo = results[0].values
    baseInfo = baseInfo.map((meterInfo) => ({
      id: meterInfo[0],
      phase: meterInfo[1],
      name: meterInfo[2]
    }))

    if (caseName === 'base') {
      return baseInfo
    } // ELSE
    // Check the modifications in the base case
    const modCase = this.cases[caseName].mod
    if ('Phase' in modCase) {
      const phaseChange = modCase.Phase
      const idList = phaseChange.Meter
      const phases = phaseChange.ph
      const phaseMap = {}
      idList.forEach((idMeter, idx) => {
        phaseMap[idMeter] = phases[idx]
      })
      const caseInfo = baseInfo.map((meterObj) => {
        if (meterObj.id in phaseMap) {
          const newMeterObj = meterObj
          newMeterObj.phase = phaseMap[meterObj.id]
          return newMeterObj
        }
        return meterObj
      })
      return caseInfo
    }
    return baseInfo
  }

  getNewConnPoint (caseName) {
    const mods = this.cases[caseName].mod
    if (mods.CGP_New.Node.length > 0) {
      return mods.CGP_New
    }
    return {}
  }

  async updateDB (caseName, referenceCaseName, db) {
    // Delete CGP_New added in reference case
    const referenceNewConnPoints = this.getNewConnPoint(referenceCaseName)
    if (Object.keys(referenceNewConnPoints).length > 0) {
      referenceNewConnPoints.ref.forEach((connPoint, index) => {
        db.exec(`
        DELETE FROM CGP WHERE ID=` + referenceNewConnPoints.CGP[index])
      })
    }
    // A dd CGP_New added in new case
    const newConnPoints = this.getNewConnPoint(caseName)
    if (Object.keys(newConnPoints).length > 0) {
      newConnPoints.ref.forEach((connPoint, index) => {
        db.exec(`
        INSERT INTO CGP (ID, Mode, Bus, EV_Count_1ph, PV_Count_1ph, EV_Count_3ph, PV_Count_3Ph, Pmax, Name)
        VALUES (` +
        newConnPoints.CGP[index] + ',' +
        newConnPoints.mode[index] + ', ' +
        newConnPoints.Node[index] + ',0, 0, 0, 0, ' +
        newConnPoints.Pmax[index] + ', \'' +
        connPoint + '\')')
      })
    }
  }

  async updateResults (db) {
    // Constants initialization for NET
    // const counts = db.exec('SELECT COUNT(ID) AS n, \'buses\' AS Description FROM Bus UNION ALL SELECT COUNT(ID) AS n, \'lines\' AS Description FROM Line;')[0].values
    const [tcount] = db.exec('SELECT StepCount FROM CasePar;')[0].values[0]
    // Setup Buses and Lines data
    this.Results.NewCase(db, tcount, this.Results.BufferCount)
  }

  getGrid () {
    function res2JSON (table) {
      /*
      Args:
          * keys: array with the name of the keys
          * values: array of arrays with the values ordered
                    in the same way as the keys (they should
                    have the same length also)
      */
      const result = []

      if (table !== undefined) {
        let row
        let key
        const { columns, values } = table
        for (let i = 0; i < values.length; i += 1) {
          row = {}
          for (let j = 0; j < columns.length; j += 1) {
            key = columns[j]
            row[key] = values[i][j]
          }
          result.push(row)
        }
      }
      return result
    }

    function startGrid (db) {
      const response = {}

      const limits = db.exec('SELECT max(X) as Xmax, min(X) as Xmin, max(Y) as Ymax, min(Y) as Ymin FROM Seg')[0].values[0]
      response.Xmax = limits[0]
      response.Xmin = limits[1]
      response.Ymax = limits[2]
      response.Ymin = limits[3]

      const networks =
      db.exec(`
        SELECT DISTINCT(Area.Name) AS NAME, 
        Base.kV AS VOLTAGE_LEVEL 
        FROM Area, Bus, Base 
        WHERE Area.ID = Bus.Area1 
        AND Base.ID=Bus.Base`)[0]
      response.NETWORKS = res2JSON(networks)

      const stations =
      db.exec(`
      SELECT Station.ID, Station.TYPE, Station.NAME, Station.X, Station.Y, group_concat(Station.LEVEL) AS VOLTAGE_LEVELS, group_concat(Station.NETWORK) AS NETWORKS
      FROM (
        SELECT Station.ID, Station.TYPE, Station.NAME, Station.X, Station.Y, Station.LEVEL, CASE WHEN Station.LEVEL<1 THEN Station.NAME ELSE Switch.ID END AS NETWORK
        FROM (
          SELECT Station.ID, 1 AS TYPE, Station.Name, Station.X, Station.Y, Base.kV AS LEVEL, Bus.Area1 AS ID_NETWORK
          FROM Station, Base, Transformer2, Bus, Winding
          WHERE Transformer2.Station = Station.ID AND Base.ID = Bus.Base AND Winding.Transformer = Transformer2.ID AND Winding.Bus = Bus.ID
        ) Station
        LEFT JOIN Switch ON Switch.ID = Station.ID_NETWORK
      ) Station
      GROUP BY Station.ID`)[0]
      response.STATIONS = res2JSON(stations).map((station) => {
        const outStation = {}
        outStation.ID = station.ID
        outStation.NAME = station.NAME
        outStation.NETWORKS = station.NETWORKS?.split(',').map((network) => network.toString())
        outStation.TYPE = station.TYPE
        outStation.VOLTAGE_LEVELS = station.VOLTAGE_LEVELS.split(',').map((level) => parseFloat(level))
        outStation.X = station.X
        outStation.Y = station.Y
        return outStation
      })

      //  Levels
      const levels = db.exec(`SELECT Base.kV AS LEVEL
                      FROM Line, Bus, Base
                      WHERE  Line.Bus1=Bus.ID AND Bus.Base = Base.ID    
                      GROUP BY Base.kV`)[0]
      const grid = {}
      // lines
      levels.values.forEach((level) => {
        const levelGrid = {}
        const lines =
          db.exec(`SELECT Line.ID AS ID, 
          group_concat(Seg.X) AS X, 
          group_concat(Seg.Y) AS Y, 
          Line.Str AS FORWARD, 
          Base.kV AS LEVEL, 0 AS CGP_HC, 
          Area.Name AS NETWORK
          FROM Line, Area
          JOIN Bus ON Line.Bus1=Bus.ID
          JOIN Seg ON Line.ID=Seg.Line
          JOIN Base ON Bus.Base = Base.ID
          LEFT JOIN Station ON Bus.Area1 = Station.ID
          WHERE Bus.Area1=Area.ID AND LEVEL = ` + level + `
          GROUP BY Seg.Line`)[0]
        const levelLines = res2JSON(lines).map((line) => {
          const outLine = {}
          outLine.ID = line.ID
          outLine.NETWORK = line.NETWORK
          outLine.FORWARD = line.FORWARD
          outLine.LEVEL = line.LEVEL
          outLine.X = line.X.split(',').map((x) => parseFloat(x))
          outLine.Y = line.Y.split(',').map((y) => parseFloat(y))
          return outLine
        })
        levelGrid.Lines = levelLines

        const closedFuses =
        db.exec(`SELECT Fuses.ID, Fuses.NETWORK, Fuses.X1, Fuses.Y1, Bus.X AS X2, Bus.Y AS Y2, Fuses.Bus1, Fuses.Bus2, Fuses.LEVEL FROM
          (SELECT Switch.ID, Area.Name AS NETWORK, Base.Kv AS LEVEL, Bus.X AS X1, Bus.Y AS Y1, Switch.Bus1, Switch.Bus2 FROM Bus
            INNER JOIN Switch ON bus.ID = Switch.Bus1
            INNER JOIN Base ON Base.ID = Bus.Base and Base.kV = ${level}
            INNER JOIN Area ON Area.ID = Bus.Area1
            WHERE Switch.State1 = 1
          ) Fuses
          INNER JOIN Bus ON Fuses.Bus2 = Bus.ID
          WHERE Fuses.ID NOT IN (
            SELECT Switch.ID
              FROM Transformer2
              INNER JOIN Winding ON Transformer2.ID = Winding.Transformer
              INNER JOIN Bus ON Winding.Bus = Bus.ID
              INNER JOIN Switch ON Bus.ID = Switch.Bus1
        )`)[0]
        levelGrid.Closed_Fuses = res2JSON(closedFuses)

        const openFuses =
        db.exec(`SELECT Fuses.ID, Fuses.NETWORK, Fuses.X1, Fuses.Y1, Bus.X AS X2, Bus.Y AS Y2, Fuses.Bus1, Fuses.Bus2, Fuses.LEVEL FROM
          (SELECT Switch.ID, Area.Name AS NETWORK, Base.Kv AS LEVEL, Bus.X AS X1, Bus.Y AS Y1, Switch.Bus1, Switch.Bus2 FROM Bus
            INNER JOIN Switch ON bus.ID = Switch.Bus1
            INNER JOIN Base ON Base.ID = Bus.Base and Base.kV = ${level}
            LEFT JOIN Area ON Area.ID = Bus.Area1
            WHERE Switch.State1 = 0
          ) Fuses
          INNER JOIN Bus ON Fuses.Bus2 = Bus.ID
          WHERE Fuses.ID NOT IN (
            SELECT Switch.ID
              FROM Transformer2
              INNER JOIN Winding ON Transformer2.ID = Winding.Transformer
              INNER JOIN Bus ON Winding.Bus = Bus.ID
              INNER JOIN Switch ON Bus.ID = Switch.Bus1
        )`)[0]
        levelGrid.Open_Fuses = res2JSON(openFuses)

        const switchboxes =
        db.exec(`SELECT Station.ID, Station.Name as NAME, Station.X, Station.Y FROM (SELECT Winding.ID, Winding.Transformer, Bus.ID as Bus, min(Base.kV) as kV
        FROM Winding
        INNER  JOIN Bus ON Winding.Bus = Bus.ID
        INNER JOIN Base ON Base.ID = Bus.Base
        GROUP BY Winding.Transformer) windingsLevel
        INNER JOIN Transformer2 ON Transformer2.ID = windingsLevel.Transformer
        INNER JOIN Station ON Transformer2.Station = Station.ID
        WHERE windingsLevel.kV = ${level}`)[0]
        const switchboxJSON = res2JSON(switchboxes)
        switchboxJSON.forEach(switchbox => {
          const fuse =
          db.exec(`SELECT Switches.CLOSED, Switches.ID, Switches.NAME, Line.ID as L, CASE WHEN Switches.Bus2 = Line.Bus1 THEN true ELSE false END as FORWARD
          FROM (SELECT Switch.State1 AS CLOSED, Switch.ID, "F"||Switch.ID AS NAME, Switch.Bus1, Switch.Bus2
          FROM (SELECT Winding.ID AS WINDING_ID, Bus.ID AS BUS_ID, Bus.X, Bus.Y, Base.kV, Transformer2.Name AS SWITCHBOX_NAME
          FROM Bus,  Winding, Base, Transformer2, Station
          WHERE Winding.Bus = Bus.ID and Base.ID=Bus.Base AND Transformer2.ID=Winding.Transformer AND Transformer2.Station=Station.ID AND Station.Name='${switchbox.NAME}') TF_NODES
          JOIN Switch ON Switch.Bus1 = TF_NODES.BUS_ID) Switches
          INNER JOIN Bus ON Switches.Bus2 = Bus.ID
          INNER JOIN Line ON Switches.Bus2 = Line.Bus2 OR Switches.Bus2= Line.Bus1`)[0]
          const switchboxFuse = (res2JSON(fuse))
          switchbox.fuses = switchboxFuse
        })
        levelGrid.Switch_Boxes = switchboxJSON

        const connPoints =
        db.exec(`SELECT CGP.ID AS ID, Bus.X as X, Bus.Y as Y,
          Area.Name AS NETWORK, 
          1 AS TYPE, 
          Base.kV AS LEVEL
          FROM CGP, Bus, Base, Area
          WHERE CGP.Bus=Bus.ID 
          AND Bus.Area1=Area.ID
          AND Bus.Base = Base.ID
          AND LEVEL = ` + level)[0]
        levelGrid.Conn_Points = res2JSON(connPoints)

        levelGrid.VOLTAGE = level
        grid[level] = levelGrid
      })
      response.Grid = grid
      return response
    }

    return startGrid(this.DB)
  }

  getQuality () {
    const queryResults = this.DB.exec('SELECT * FROM Capacity')
    const values = queryResults[0].values[0]
    const qualityParams = {}
    queryResults[0].columns.forEach((key, idx) => {
      if (key !== 'ID') {
        qualityParams[key] = values[idx]
      }
    })
    return qualityParams
  }

  setQuality (newParams) {
    // UPDATE VALUES IN THE DATABASE
    let setStatement = ''
    const ACCEPTED_PROPERTIES = ['Vmax', 'Vmin', 'NegSeqMax', 'ZeroSeqMax', 'OverCurrentRatio', 'OverLoadingRatio']
    Object.entries(newParams).forEach(([key, val]) => {
      if (ACCEPTED_PROPERTIES.includes(key)) {
        setStatement = setStatement.concat(`${key}=${val},`)
        if (key === 'OverCurrentRatio') {
          this.Results.ImaxRatio = val
        } else if (key === 'OverLoadingRatio') {
          this.Results.SmaxRatio = val
        } else {
          this.Results[key] = val
        }
      } else {
        console.error('Some keys not allowed!')
      }
      // UPDATE VALUES IN THE Results Object
    })
    // Remove last comma
    setStatement = setStatement.slice(0, -1)
    this.DB.exec(`UPDATE Capacity SET ${setStatement} WHERE ID=1`)

    // SEND DATA TO BACKEND
    const myHeaders = new Headers()
    myHeaders.append('content-type', 'application/json')

    const data = JSON.stringify(newParams)

    patch(`${this.host}/digital-twin/project?name=${this.projectName}`, myHeaders, data)
  }

  getParams () {
    function epoch2date (millis) {
      const d = new Date(millis)
      const y = d.getFullYear()
      const m = (`0${(d.getMonth() + 1)}`).slice(-2)
      const day = (`0${d.getDate()}`).slice(-2)
      return `${y}-${m}-${day}`
    }
    const showParams = ['SimMode', 'Freq', 'LoadScale', 'StartTime', 'StepTime', 'StepCount', 'BasePower']
    const results = this.DB.exec(`SELECT ${showParams.join()} FROM CasePar;`)[0].values[0]
    const resObj = {}
    for (let i = 0; i < showParams.length; i += 1) {
      resObj[showParams[i]] = results[i]
    }
    resObj.StartTime = this.startTime
    // ADD DATES HUMAN READABLE
    resObj.InitDate = epoch2date(resObj.StartTime)
    resObj.EndDate = epoch2date(resObj.StartTime + (resObj.StepCount - 1) * 3600000)
    return resObj
  }

  getBusId (elementvalue) {
    const busid = this.DB.exec(`SELECT Bus from CGP where ID = ${elementvalue}`)[0].values
    return busid
  }

  getLineId (busid) {
    const lineid = this.DB.exec(`SELECT ID, CASE WHEN Bus1=${busid} THEN true ELSE false END AS ISSRCONBUS from LINE WHERE (Bus1 = ${busid}) OR (Bus2 = ${busid})`)[0].values
    return lineid
  }
  /*
  getLineFlow (busid) {
    const flowId = this.DB.exec(`SELECT Str from LINE WHERE (Bus1 = ${busid} AND Str = 0) OR (Bus2 = ${busid} AND Str=1)`)[0].values[0]
    return flowId
  }
  */

  getStationBusId (stationid) {
    const busid = this.DB.exec(`SELECT Bus2 FROM Transformer WHERE Station = ${stationid}`)[0].values
    return busid
  }

  getStationLines (stationid) {
    const busid = this.DB.exec(`SELECT a.Bus2, b.ID, b.BUS1, b.BUS2
    FROM (SELECT BUS2 FROM SWITCH WHERE BUS1=(SELECT BUS2 FROM TRANSFORMER WHERE STATION=${stationid})) a
    INNER JOIN LINE b On a.Bus2=b.BUS1 OR a.BUS2=b.BUS2`)[0].values
    return busid
  }
}

export default DigitalTwin
