import i18n from '@/plugins/i18n'
export const TOGGLE_ELEMENT = 'TOGGLE_ELEMENT'
export const SET_ELEMENT = 'SET_ELEMENT'
export const APPEND_CASE = 'APPEND_CASE'
export const ADD_DT_CHANGES = 'ADD_DT_CHANGES'
export const DELETE_ELEMENT = 'DELETE_ELEMENT'
export const RESET_STATE = 'RESET_STATE'

export const THEME_STYLES = {
  dark: 'dark',
  light: 'light'
}

export const COUNTRY_CODES = {
  'Europe/Madrid': 'spain',
  'Europe/Stockholm': 'sweden'
}

export const ICONS = {
  PV: 'pv-yellowcircle-solid.svg',
  EV: 'car-yellowcircle-solid.svg',
  HP: 'heatpump-yellowcircle-solid.svg',
  ESS: 'battery-yellowcircle-solid.svg',
  Phase: 'changephase-yellowcircle-solid.svg',
  RemoveUser: 'deleteuser-yellowcircle-solid.svg',
  More: 'more-yellowcircle-solid.svg',
  Switch: 'switchfuse-yellowcircle-solid.svg',
  CGP_New: 'connectionpoint-yellowcircle-solid.svg',
  CP: 'custom-profile-yellowcircle-solid.svg',
  Disable_Element: 'disable-line-yellowcircle-solid.svg', // Change
  Disable_Element_Line: 'disable-line-yellowcircle-solid.svg',
  Disable_Element_Station: 'disable-fuse-yellowcircle-solid.svg', // Change
  Disable_Element_ConnectionPoint: 'disable-fuse-yellowcircle-solid.svg'// Change
}

export const TYPE_VALIDATIONS = {
  required: (value) => !!value || i18n.t('validations.item_required'),
  min: (min) => (value) => (value >= min) || `${i18n.t('validations.equal_or_greater')} ${min}`,
  minMax: (min, max) => (value) => !!(value >= min && value <= max) || `${i18n.t('validations.item_between')} ${min} ${i18n.t('and')} ${max}`,
  integer: (value) => (value % 1 === 0) || i18n.t('validations.integer'),
  notSpecial: (value) => (value && ![...value.matchAll(/[^a-zA-Z0-9-äöåÄÖÅ ]/g)].length) || i18n.t('validations.not_special_character')
}

const {
  required, min, minMax, integer, notSpecial
} = TYPE_VALIDATIONS

export const ELEMENT_TYPES = {
  C: 'stations',
  TD: 'transformers',
  F: 'feeders'
}

export const VALIDATIONS = {
  ndevs: [required, min(1), integer],
  rcp: [required],
  c: [required, min(0)],
  pf: [required, minMax(0.01, 1)],
  pfpv: [required, minMax(0.1, 1)],
  q_max: [required, minMax(1, 50000)],
  vreg: [required, minMax(0.90, 1.10)],
  dv: [required, minMax(0.01, 0.10)],
  dQ: [required, minMax(0.01, 0.50)],
  kmd: [required, min(0)],
  stdkmd: [required, minMax(0, 100)],
  nhP: [required],
  cop: [required, minMax(1, 5)],
  pp: [required, minMax(1, 50000)],
  caseName: [required],
  loadScale: [required, min(0.1)],
  newProjectName: [required, notSpecial],
  hcReservePeriod: [required, minMax(1, 24)],
  Pmax: [required, integer],
  sampleTime: [required, min(1), integer],

  // ESS Parametes Validation
  pmaxin: [required, minMax(0.1, 10000.0)],
  pmaxout: [required, minMax(0.01, 10000.0)],
  qmaxin: [required, minMax(0.01, 10000.0)],
  qmaxout: [required, minMax(0.01, 10000.0)],
  emax: [required, minMax(0.01, 10000.0)],
  soc1: [required, minMax(0, 100)],
  soc2: [required, minMax(0, 100)],
  soc: [required, minMax(0, 100)],
  p_pq_mode: [required, minMax(0, 1000)],
  q_pq_mode: [required, minMax(0, 1000)],
  dv1: [required, minMax(0.005, 0.04)],
  dv2: [required, minMax(0.005, 0.04)],
  essvreg: [required, minMax(0.98, 1.02)],
  trigerlow: [required, minMax(0, 60)],
  trigerhigh: [required, minMax(60, 100)],
  trigerband: [required, minMax(0.01, 1.0)]
}

export const DTCHANGES_EMPTY = () => ({
  Switch: [],
  CGP_New: {
    ref: [],
    Pmax: [],
    Node: [],
    mode: [],
    CGP: []
  },
  Disable_Element: {
    id: [],
    element_type: [],
    mode: []
  },
  PV: {
    ndevs: [],
    CGP: [],
    pp: [],
    ph: [],
    mode: [],
    q_max: [],
    pf: [],
    vreg: [],
    dv1: [],
    dv2: [],
    dQ: []
  },
  EV: {
    ndevs: [],
    CGP: [],
    ph: [],
    rcp: [],
    c: [],
    kmd: [],
    stdkmd: [],
    pf: [],
    start_min: [],
    start_max: []
  },
  HP: {
    ndevs: [],
    CGP: [],
    nhP: [],
    cop: [],
    ph: [],
    pf: []
  },
  Phase: {
    CGP: [],
    Meter: [],
    ph: []
  },
  LoadScale: 1,
  CP: {
    CGP: [],
    ph: [],
    ref: [],
    P: [],
    Q: [],
    sampleTime: [],
    ndevs: []
  },
  ESS: {
    mode: [],
    Node: [],
    ph: [],
    SigID: [],
    SigMode: [],
    PmaxIn: [],
    PmaxOut: [],
    QmaxIn: [],
    QmaxOut: [],
    Emax: [],
    SOC1: [],
    SOC2: [],
    SOC: [],
    P_pq_mode: [],
    Q_pq_mode: [],
    dv1: [],
    dv2: [],
    vreg: [],
    TrigerLow: [],
    TrigerHigh: [],
    TrigerBand: []
  },
  NewBusCount: 0
})

export const INITIAL_STATE = () => ({
  DTProgress: 0,
  theme: 'dark',
  contextMenu: {
    isVisible: false,
    position: {
      x: 0,
      y: 0
    },
    items: []
  },
  connectionPoint: {},
  contextMenuElement: {},
  markers: [],
  currentCase: undefined,
  creatingCase: false,
  referenceCase: undefined,
  simulatedCase: undefined,
  currentMarker: undefined,
  violations: {
    dialog: false,
    data: []
  },
  DTChanges: DTCHANGES_EMPTY(),
  DTProject: {
    name: undefined,
    networks: [],
    networksInfo: [],
    sim: {},
    quality: {}
  },
  DTProjectInfo: {
    numberOfCases: null,
    date: null
  },
  DTResults: {},
  colorScale: {},
  colorScaleLimits: {
    min: {
      absolute: {
        voltage: 218.5,
        current: 0,
        activePower: 0,
        reactivePower: 0,
        losses: 0,
        unbalance: 0
      },
      relative: {
        voltage: 0,
        current: 0,
        activePower: 0,
        reactivePower: 0,
        losses: 0,
        unbalance: 0
      }
    },
    max: {
      absolute: {
        voltage: 218.5,
        current: 0,
        activePower: 0,
        reactivePower: 0,
        losses: 0,
        unbalance: 0
      },
      relative: {
        voltage: 1,
        current: 1,
        activePower: 1,
        reactivePower: 1,
        losses: 1,
        unbalance: 1
      }
    }
  },
  instant: undefined,
  measurement: {
    measurement: 'voltage',
    submeasurement: 'min'
  },
  projectNetworks: [],
  relativeScale: false,
  Plots: {
    phaseA: undefined,
    phaseB: undefined,
    phaseC: undefined,
    phaseN: undefined
  },
  planningType: undefined,
  planningConfigEV: {
    rcp: undefined,
    c: undefined,
    kmd: undefined,
    stdkmd: undefined,
    pf: undefined
  },
  planningConfigPV: {
    percentage: undefined,
    initialPower: undefined,
    powerSteps: undefined
  },
  logged: false,
  measurements: {
    inventory: [],
    currentSelector: {
      tabs: []
    },
    currentTab: {},
    area: null,
    element: null,
    assets: {},
    loading: false,
    elementDetails: {
      title: null,
      info: {}
    },
    selected: {},
    mapReady: false,
    timeSelection: {
      Measurements: {
        start: null,
        end: null
      },
      Events: {
        start: null,
        end: null
      }
    },
    plotData: [],
    visiblePlots: [],
    eventsPlotData: [],
    selectedNetworksNames: [],
    measurement: null,
    operator: null,
    drawElementsOnMap: {
      Measurements: null,
      Events: null
    },
    heatmapValue: [],
    metersCodeMap: null,
    metersCodeMapTypes: [],
    selectedMeasurementId: null,
    isAllSelected: false,
    feedback: {}
  },
  showSetAreaForm: false,
  syncMap: false,
  areaMap: window.sessionStorage.areaMap &&
    JSON.parse(window.sessionStorage.areaMap),
  elementHovered: null,
  areaMapName: null,
  areaGrid: null,
  hostingCapacity: {
    HCManageResults: [],
    HCReservations: [],
    HCNetworksWithLines: [],
    checkReservations: []
  },
  click: {
    lat: null,
    lng: null,
    idElement: null
  },
  creatingChange: {
    newElement: false,
    type: '',
    idElement: null
  },
  TIA: {
    tabTIA: 0,
    tabTIAMonitoring: true,
    timeMachine: null,
    TIAMap: {
      activatedStations: null,
      stationSelected: null,
      maxPowerStation: null,
      nameStationSelected: null,
      contextMenu: false
    },
    TIAMonitoring: {
      supplyPointSelectedName: null,
      supplyPointSelectedId: null,
      periodSelected: 'today',
      changePower: false
    }
  },
  vsource: [],
  overloadAnalysis: [],
  typeElement: '',
  idElementToShow: '',
  sqlWasmCreated: false
})
