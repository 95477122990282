<template>
  <v-app>
    <splitpanes
      class="default-theme"
      @resize="resizePanel"
    >
      <pane style="max-height=100%; position: relative">
        <MapBase
          v-if="gridBounds && networksDetailsWithHeatmapLines && !SLDView"
          :digital-twin-map="true"
          :mode="mode"
          :enabled-context-menu="true"
          :networks="networksDetailsWithHeatmapLines"
          :open-fuses="openFusesSimulated"
          :list-elements="listElements"
          :grid-bounds="gridBounds"
          @click="click"
          @changeMode="changeMode"
          @toggleNavbar="showBar = !showBar"
        />
        <SingleLineDiagram
          v-else-if="SLDView"
          ref="sld"
          :name="`sld-${SLDElement}`"
          :station-name="SLDElement?.toString()"
          scrollable="true"
          :plot-flow="true"
          :legend-segment-top="legendSegmentsSLD"
          :segment-color="colorSegmentsSLD"
          :bus-color="colorBusSLD"
          :node-legend="legendBusSLD"
          :devices="currentMod"
          @click="click"
        />
        <MeasurementSelector
          v-if="digitalTwinResults"
          :sld-view="SLDView"
          :top-high="!showBar"
        />
        <HideRightPanelBtn
          v-if="toggleButtonShow"
          :panel-show="panelShow"
          :toggle-panel-show="togglePanelShow"
        />
        <ColorScale
          v-if="digitalTwinResults"
          :lower="colorScaleLower"
          :middle="colorScaleMiddle"
          :upper="colorScaleUpper"
          :colors="colorScaleColors"
          :prefix="relativeUnit"
          @changeUpperLimit="changeUpperLimit"
          @changeLowerLimit="changeLowerLimit"
          @setColorScale="setColorScale"
        />
        <CMTimeSlider
          v-if="digitalTwinResults"
          :steps="sim.StepCount"
          :init="sim.StartTime"
          @changeInstant="changeInstant"
        />
        <v-switch
          v-if="showBar"
          v-model="SLDView"
          class="switchMapView"
          :class="{ tophigh: SLDView, toplow: showBar && !SLDView }"
          inset
          :color="'#f4c020'"
          :label="SLDView ? $t('map.map_view') : $t('map.sld')"
          :disabled="SLDElement === null || levelVoltage !== '0.4'"
        />
      </pane>
      <pane
        v-if="panelShow"
        :size="40"
        :min-size="40"
        :max-size="60"
      >
        <DigitalTwinPanel
          :id-element="idElement"
          :id-station="idStation"
          :level-voltage="levelVoltage"
          :type-element="typeElement"
          :id-element-to-show="idElementToShow?.toString()"
          :station="station"
          :sim-results="simResults"
          :pv-list="pvList"
          :ev-list="evList"
          :hp-list="hpList"
          :load-scale-list="loadScaleList"
          :stations="stations"
          :sim-headers="simHeaders"
          :data-plot="dataPlot"
          :data-plot-l-c-p="dataPlotLCP"
          :data-plot-v="dataPlotV"
          :data-plot-i="dataPlotI"
          :data-plot-p-s="dataPlotPS"
          :data-plot-p-p="dataPlotPP"
          :data-plot-p-q="dataPlotPQ"
          :data-plot-p-l="dataPlotPL"
          :data-plot-p-u-f-c="dataPlotPUFC"
          :data-plot-p-u-f-v="dataPlotPUFV"
          :type-value="typeValue"
          :sampling-period="samplingPeriod"
          :digital-twin-results="digitalTwinResults"
          :windings-list="windings"
          :panel-only-title="panelOnlyTitle"
          @updatePanel="updatePanel"
          @updateStation="updateStation"
        />
      </pane>
    </splitpanes>
  </v-app>
</template>

<script>
import { Splitpanes, Pane } from 'splitpanes'
import { mapGetters, mapState } from 'vuex'

import 'leaflet/dist/leaflet.css'
import 'splitpanes/dist/splitpanes.css'

import MapBase from '@/components/Map/MapBase.vue'
import ColorScale from '@/components/Common/CMColorScale.vue'
import MeasurementSelector from '@/components/DigitalTwin/MapAddOn/MapAddOnMeasurementSelector.vue'
import CMTimeSlider from '@/components/Common/CMTimeSlider.vue'
import DigitalTwinPanel from '@/components/DigitalTwin/DTPanel.vue'
import SingleLineDiagram from '@/components/Map/SingleLineDiagram.vue'
import HideRightPanelBtn from '@/components/Common/CMHideRightPanelBtn.vue'

import stationKpis from '@/mixins/stationKpis'
import lineKpis from '@/mixins/lineKpis'
import cgpKpis from '@/mixins/cgpKpis'
import mapColorScaleMixin from '@/mixins/map/mapColorScaleMixin'
import maths from '@/mixins/maths'
import vuexMixin from '@/mixins/vuexMixin'

import formatDate from '@/utils/formatDate'

export default {
  name: 'DigitalTwinNewConnections',

  components: {
    MapBase,
    ColorScale,
    CMTimeSlider,
    MeasurementSelector,
    DigitalTwinPanel,
    Splitpanes,
    Pane,
    SingleLineDiagram,
    HideRightPanelBtn
  },

  mixins: [stationKpis, lineKpis, cgpKpis, mapColorScaleMixin, maths, vuexMixin],

  beforeRouteLeave (to, from, next) {
    this.$store.dispatch('resetState')
    next()
  },
  data () {
    return {
      projectName: this.$store.state.DTProject.name,
      projectNetworks: this.$store.state.DTProject.networks,
      // networksDetails: this.$store.state.DTProject.networksInfo,
      sim: this.$store.state.DTProject.sim,
      quality: this.$store.state.DTProject.quality,
      mode: 'element_mode',
      panelShow: false,
      panelOnlyTitle: false,
      showBar: true,
      levelVoltage: null,
      idElement: null,
      idStation: null,
      typeElement: null,
      idElementToShow: null,
      grid: undefined,
      station: null,
      stationLines: null,
      stationConnectionPoints: null,
      gridBounds: undefined,
      digitalTwinReceived: false,
      networksDetailsWithHeatmapLines: null,
      listElements: {
        stationsWithID: [],
        stations: [],
        lines: [],
        fuses: [],
        connectionPoints: []
      },
      toggleButtonShow: false,
      openFusesSimulated: [],
      colorScaleLimits: {
        voltage: this.setVoltageLimits,
        current: this.setCurrentLimits,
        activePower: this.setActivePowerLimits,
        reactivePower: this.setReactivePowerLimits,
        unbalance: this.setUnbalanceLimits,
        losses: this.setLossesLimits
      },
      submeasurementType: {
        min: 0,
        max: 0,
        avg: 0,
        phA: 1,
        phB: 1,
        phC: 1,
        phN: 1
      },
      measurementCalculation: {
        // voltage: this.getVoltageMeasurement,
        voltage: this.getVoltageMeasurement,
        current: this.getCurrentMeasurement,
        activePower: this.getActivePowerMeasurement,
        reactivePower: this.getReactivePowerMeasurement,
        unbalance: this.getUnbalanceMeasurement,
        losses: this.getLossesMeasurement
      },
      relativeUnits: {
        voltage: 'p.u.',
        current: '%',
        activePower: 'p.u',
        reactivePower: 'p.u.',
        losses: 'W',
        default: ''
      },
      absoluteUnits: {
        voltage: 'V',
        current: 'A',
        activePower: 'kW',
        reactivePower: 'kVAr',
        losses: 'W',
        unbalance: '%',
        default: ''
      },
      host: this.$API_HOST,
      port: this.$API_PORT,
      loading: false,
      flagProjectCreated: false,
      flagCaseCreated: false,
      flagCTsimulated: false,
      tempdt: null,
      tempStationId: null,
      simResults: null,
      pvList: null,
      evList: null,
      hpList: null,
      loadScaleList: null,
      simHeaders: null,
      dtObject: null,
      dataPlot: null,
      dataPlotLCP: null,
      dataPlotV: null,
      dataPlotI: null,
      dataPlotPS: null,
      dataPlotPP: null,
      dataPlotPQ: null,
      dataPlotPL: null,
      dataPlotPUFC: null,
      dataPlotPUFV: null,
      typeValue: 1,
      samplingPeriod: 1,
      t0: 0,
      t1: this.$store.state.DTProject.sim.StepCount,
      SLDView: false,
      SLDFlow: false,
      sqlNetworks: null,
      isLevel: 0,
      idTransformer: null,
      ids: 0,
      alltransfo: null,
      transformers: [],
      relativeUnit: null,
      SLDElement: null,
      windings: [],
      idWinding: null
    }
  },

  computed: {
    ...mapState({
      measurementObject: (state) => state.measurement,
      currentCase: (state) => state.currentCase,
      creatingCase: (state) => state.creatingCase,
      referenceCase: (state) => state.referenceCase,
      simulatedCase: (state) => state.simulatedCase,
      digitalTwinResults: (state) => state.DTResults,
      openFuses: (state) => state.DTProject.openFuses,
      networksDetails: (state) => state.DTProject.networksInfo,
      overloadAnalysis: (state) => state.overloadAnalysis
    }),

    ...mapGetters(['currentMod']),

    voltageLowerLimit () {
      return this.digitalTwinResults.Results.GetAreaBusesVmin(0, 0, this.sim.StepCount)
    },
    voltageUpperLimit () {
      return this.digitalTwinResults.Results.GetAreaBusesVmax(0, 0, this.sim.StepCount)
    }
  },

  watch: {
    measurementObject () {
      // adjust scale color
      this.setScaleLimits()
      // color the heatmap
      this.refreshColors()
    },

    simulatedCase () {
      let initDaten = new Date(this.$store.state.DTProject.sim.InitDate).getTime()
      let endDaten = new Date(this.$store.state.DTProject.sim.EndDate).getTime()
      const fechaIni = new Date(this.$store.state.DTProject.sim.InitDate)
      const desfaseIni = fechaIni.getTimezoneOffset()
      initDaten += desfaseIni * 60 * 1000
      const fechaFin = new Date(this.$store.state.DTProject.sim.EndDate)
      const desfaseFin = fechaFin.getTimezoneOffset()
      endDaten += desfaseFin * 60 * 1000

      const diffInitTStarT = initDaten - this.$store.state.DTProject.sim.StartTime
      this.frame1 = diffInitTStarT / (1000 * 60 * 60) // in hours
      this.frame1 *= (3600 / this.$STEP_TIME) // due to sampleTime
      this.frame2 = (endDaten - initDaten) / (1000 * 60 * 60) // in hours
      this.frame2 *= (3600 / this.$STEP_TIME) // due to sampleTime
      this.frame2 = this.frame2 + 24 *
      (3600 / this.$STEP_TIME) + this.frame1 // due to sampleTime
      this.case = this.simulatedCase &&
          this.digitalTwinResults.cases[this.simulatedCase]?.idx
      this.refreshColors()
      this.updatePanel(this.frame1, this.frame2)
      this.setCaseViolations()
    },
    overloadAnalysis: {
      deep: true,
      handler (val) {
        this.getKPIs(this.digitalTwinResults, this.frame1, this.frame2, this.levelVoltage)
      }
    }
  },

  mounted () {
    const measurement = {
      measurement: 'voltage',
      submeasurement: 'min'
    }
    this.$store.dispatch('setElement', { path: 'measurement', value: measurement })
    this.getGrid()
    this.gridBounds = {
      Xmin: this.grid.Xmin, Ymin: this.grid.Ymin, Xmax: this.grid.Xmax, Ymax: this.grid.Ymax
    }
    this.$store.dispatch('setElement', { path: 'simulatedCase', value: this.currentCase })
    this.setListElements()
    this.$emit('networkReady')
  },

  methods: {
    togglePanelShow (value) {
      this.panelShow = value
    },
    setListElements () {
      this.sqlNetworks = this.$store.state.DTProject.networksInfo

      this.sqlNetworks.forEach((elementNetwork) => {
        const { level } = elementNetwork
        elementNetwork.stations.forEach((station) => {
          this.listElements.stations.push([`${level}`, station.ID])
        })
        elementNetwork.networks.forEach((grid) => {
          const networkName = grid.network.NAME
          grid.lines.forEach((elementLine) => {
            this.listElements.lines.push([`${level}`, networkName, elementLine.ID])
          })
          grid.connection_points.forEach((elementCP) => {
            this.listElements.connectionPoints.push([`${level}`, networkName, elementCP.ID])
          })
          grid.closed_fuses.forEach((elementFuses) => {
            this.listElements.fuses.push([`${level}`, networkName, elementFuses.ID])
          })
        })
      })
    },
    refreshColors () {
      if (this.SLDView) {
        this.$refs.sld.refreshSLD()
      } else {
        this.colorLines()
      }
    },
    click (level, network, elementId, elementType) {
      this.panelOnlyTitle = false
      this.levelVoltage = level
      this.toggleButtonShow = true
      this.assignClickValues(elementId, elementType, network)
      this.idElementToShow = this.idElement
      this.panelShow = true

      if (this.mode === 'element_mode' && elementType !== 'fuse') {
        if (this.typeElement === 'Station') {
          this.setStationProperties()
        } else if (this.typeElement === 'Cgp' || this.typeElement === 'ConnectionPoint') {
          this.typeElement = 'CGP'
        }

        // UPDATE T1
        let initDaten = new Date(this.$store.state.DTProject.sim.InitDate).getTime()
        let endDaten = new Date(this.$store.state.DTProject.sim.EndDate).getTime()
        const fechaIni = new Date(this.$store.state.DTProject.sim.InitDate)
        const desfaseIni = fechaIni.getTimezoneOffset()
        initDaten += desfaseIni * 60 * 1000
        const fechaFin = new Date(this.$store.state.DTProject.sim.EndDate)
        const desfaseFin = fechaFin.getTimezoneOffset()
        endDaten += desfaseFin * 60 * 1000

        const diffInitTStarT = initDaten - this.$store.state.DTProject.sim.StartTime
        this.frame1 = diffInitTStarT / (1000 * 60 * 60) // in hours
        this.frame1 *= (3600 / this.$STEP_TIME) // due to sampleTime
        this.frame2 = (endDaten - initDaten) / (1000 * 60 * 60) // in hours
        this.frame2 *= (3600 / this.$STEP_TIME) // due to sampleTime
        this.frame2 = this.frame2 + 24 *
        (3600 / this.$STEP_TIME) + this.frame1 // due to sampleTime
        this.updatePanel(this.frame1, this.frame2, level)
      } else {
        this.panelOnlyTitle = true
      }
    },
    assignClickValues (elementId, elementType, network) {
      if (this.mode === 'element_mode') {
        this.typeElement = elementType.charAt(0).toUpperCase() + elementType.slice(1) // First letter Capital
        this.idStation = network
        this.idElement = elementId.toString()
      } else {
        // TODO: Know exactly what to do in network_mode. Before it was showing the station statistics, but with medium voltage is not 1 network ->1 station
        this.typeElement = 'Network'
        this.idStation = null
        this.idElement = network
      }
      this.SLDElement = network
    },
    setStationProperties () {
      const matchingStation = this.networksDetails[0].stations.find(element => element.ID.toString() === this.idElementToShow)

      if (matchingStation) {
        this.idElementToShow = matchingStation.ID
      }

      const allTransformers = this.digitalTwinResults.DB.exec(`SELECT ID, Name from Transformer2 where Station = ${this.idElementToShow}`)[0].values
      this.transformers = allTransformers?.map(transformer => ({
        ID: transformer[0],
        Name: transformer[1]
      }))
      this.idTransformer = this.transformers[0]?.ID // Default value for transformer selection

      const allWindings = this.digitalTwinResults.DB.exec(`
        SELECT Winding.ID, Transformer2.Name, Winding.Transformer
        FROM Winding
        JOIN Transformer2 ON Winding.Transformer = Transformer2.ID
        WHERE Transformer2.Station = ${this.idElementToShow}
      `)[0].values

      const transformerIndexCounters = {}

      this.windings = allWindings?.map(winding => {
        const transformerID = winding[2]
        
        if (!transformerIndexCounters[transformerID]) {
          transformerIndexCounters[transformerID] = 0
        }
        const index = transformerIndexCounters[transformerID]
        transformerIndexCounters[transformerID]++

        return {
          ID: winding[0],
          index: index, // refSide?
          Name: `${winding[1]} - W${index + 1}`,
          TransformerID: transformerID
        }
      })

      // Selection by default for Transformer and Winding in Panel toolbar selector
      this.idWinding = this.windings[0]?.ID 
      this.idTransformer = this.windings[0]?.TransformerID

      const vsource = this.digitalTwinResults.Results.GetVsLimits(0)
      this.$store.dispatch('setElement', { path: 'vsource', value: vsource })

      if (!this.overloadAnalysis.some(station => station.idStation === this.idElement)) {
        const value = {
          idStation: this.idElement,
          consumptionLimitP: vsource[0],
          consumptionLimitQ: vsource[2],
          injectionLimitP: vsource[1],
          injectionLimitQ: vsource[3]
        }

        this.$store.dispatch('setElement', {
          path: 'overloadAnalysis',
          value: [...this.overloadAnalysis, value]
        })
      }
    },

    updatePanel (dateFrame1, dateFrame2, level) {
      // This will retain the value of the level else its has new value
      if (level !== undefined && level !== null) {
        this.isLevel = level
      } else {
        level = this.isLevel
      }

      if (this.typeElement === 'line') {
        this.typeElement = 'Line'
      }
      if (this.typeElement === 'station') {
        this.typeElement = 'Station'
      }
      if (this.typeElement === 'cgp' || this.typeElement === 'connectionPoint') {
        this.typeElement = 'CGP'
      }

      this.getKPIs(this.digitalTwinResults, dateFrame1, dateFrame2, level)
      this.getDataPlots(this.digitalTwinResults, this.idElement,
        this.typeElement, dateFrame1, dateFrame2, level)
      this.getDevicesList()
    },

    setCaseViolations () {
      const violations = this.digitalTwinResults.Results
        .GetCaseVio(this.case, 0, this.sim.StepCount) || []

      const violationsFormatted = violations.map((violation = [], index) => {
        const dateData = new Date()
        const hasInstant = violation[5] !== undefined
        hasInstant && dateData.setTime(this.sim.StartTime + violation[5] * (3600 * 1000))

        return {
          uid: index,
          violation: violation[0],
          'p.u.': violation[1].toFixed(3),
          element: violation[2],
          ID: violation[3],
          phase: violation[4],
          instant: violation[5],
          date: hasInstant
            ? formatDate(dateData)
            : 'N/A'
        }
      })

      this.setVuexElement({
        path: 'violations.data',
        value: violationsFormatted
      })
    },

    setScaleLimits () {
      this.colorScaleLimits[this.measurement](256)
      this.relativeUnit = this.getUnits(this.measurement, 1)
    },
    setVoltageLimits (op) {
      this.colorScaleLower = this.digitalTwinResults.Results
        .GetAreaBusesVmin(this.case, 0, this.sim.StepCount, op)[0]
      this.colorScaleUpper = this.digitalTwinResults.Results
        .GetAreaBusesVmax(this.case, 0, this.sim.StepCount, op)[0]
      this.colorScaleMiddle = (this.colorScaleLower + this.colorScaleUpper) / 2
    },
    setCurrentLimits (op) {
      this.colorScaleUpper = this.digitalTwinResults.Results
        .GetAreaLinesImax(this.case, 0, this.sim.StepCount, op)[0] * 100
      this.colorScaleLower = 0
      this.colorScaleMiddle = this.colorScaleUpper / 2
    },
    setActivePowerLimits (op) {
      this.colorScaleUpper = this.digitalTwinResults.Results
        .GetAreaLinesPmax(this.case, 0, this.sim.StepCount, op)[0]
      this.colorScaleLower = 0
      this.colorScaleMiddle = this.colorScaleUpper / 2
    },
    setReactivePowerLimits (op) {
      this.colorScaleUpper = this.digitalTwinResults.Results
        .GetAreaLinesQmax(this.case, 0, this.sim.StepCount, op)[0]
      this.colorScaleLower = 0
      this.colorScaleMiddle = this.colorScaleUpper / 2
    },
    setLossesLimits () {
      this.colorScaleUpper = this.digitalTwinResults.Results
        .GetAreaLineLosMax(this.case, 0, this.sim.StepCount)[0] * 1000
      this.colorScaleLower = 0
      this.colorScaleMiddle = this.colorScaleUpper / 2
    },
    setUnbalanceLimits () {
      this.colorScaleUpper = this.digitalTwinResults.Results
        .GetAreaUFmax(this.case, 0, this.sim.StepCount)[0]
      this.colorScaleLower = 0
      this.colorScaleMiddle = this.colorScaleUpper / 2
    },

    getGrid () {
      this.grid = this.digitalTwinResults.getGrid()
    },

    getDataPlots (dt, idElement, typeElement, dateFrame1, dateFrame2, level) {
      const Frame1 = dateFrame1
      const Frame2 = dateFrame2
      if (typeElement === 'Line') {
        const elementIndex = dt.Results.GetLineIndex(idElement)
        const busIndex = dt.Results.Con_Dst[elementIndex]
        // LC Current
        let phase = 0
        this.dataPlot = new Array(4)
        phase = 0
        for (phase = 0; phase <= 3; phase += 1) {
          const LDCC = dt.Results.GetLinePhaseI(this.case, Frame1, Frame2,
            elementIndex, phase)
          this.dataPlot[phase] = [[...Array(Frame2).keys()], LDCC]
        }

        // LC Power
        this.dataPlotLCP = new Array(5)
        phase = 0
        for (phase = 0; phase <= 3; phase += 1) {
          const LDCP = dt.Results.GetLineP(this.case, Frame1, Frame2, elementIndex, phase)
          this.dataPlotLCP[phase] = [[...Array(Frame2).keys()], LDCP]
        }
        const dataPlotAuxP = []
        for (let i = 0; i < this.dataPlotLCP[0][1].length; i += 1) {
          dataPlotAuxP[i] = this.dataPlotLCP[0][1][i] + this.dataPlotLCP[1][1][i] +
          this.dataPlotLCP[2][1][i] + this.dataPlotLCP[3][1][i]
        }
        this.dataPlotLCP[4] = dataPlotAuxP
        if (this.grid.Grid[level].Lines?.find((element) => element.ID === parseInt(idElement, 10)).FORWARD ===
        0) {
          phase = 0
          for (phase = 0; phase <= 3; phase += 1) {
            this.dataPlotLCP[phase][1] = this.dataPlotLCP[phase][1].map(function (x) {
              return x * (-1)
            })
          }
          this.dataPlotLCP[4] = this.dataPlotLCP[4].map(function (x) {
            return x * (-1)
          })
        }
        // Voltage
        this.dataPlotV = new Array(4)
        phase = 0
        for (phase = 0; phase <= 3; phase += 1) {
          const V = dt.Results.GetBusPhaseV(this.case, Frame1, Frame2, busIndex, phase, 0)
          this.dataPlotV[phase] = [[...Array(Frame2).keys()], V]
        }
        // Current
        this.dataPlotI = new Array(4)
        phase = 0
        for (phase = 0; phase <= 3; phase += 1) {
          const I = dt.Results.GetLinePhaseI(this.case, Frame1, Frame2, elementIndex, phase, 0)
          this.dataPlotI[phase] = [[...Array(Frame2).keys()], I]
        }
        // Power S
        this.dataPlotPS = new Array(4)
        phase = 0
        for (phase = 0; phase <= 3; phase += 1) {
          const PS = dt.Results.GetLineS(this.case, Frame1, Frame2, elementIndex, phase, 0)
          this.dataPlotPS[phase] = [[...Array(Frame2).keys()], PS]
        }
        // Power P
        this.dataPlotPP = new Array(4)
        phase = 0
        for (phase = 0; phase <= 3; phase += 1) {
          const PP = dt.Results.GetLineP(this.case, Frame1, Frame2, elementIndex, phase, 0)
          this.dataPlotPP[phase] = [[...Array(Frame2).keys()], PP]
          if (this.grid.Grid[level].Lines?.find((element) => element.ID === parseInt(idElement, 10)).FORWARD ===
          0) {
            this.dataPlotPP[phase][1] = this.dataPlotPP[phase][1].map(function (x) {
              return x * (-1)
            })
          }
        }
        // Power Q
        this.dataPlotPQ = new Array(4)
        phase = 0
        for (phase = 0; phase <= 3; phase += 1) {
          const PQ = dt.Results.GetLineQ(this.case, Frame1, Frame2, elementIndex, phase, 0)
          this.dataPlotPQ[phase] = [[...Array(Frame2).keys()], PQ]
          if (this.grid.Grid[level].Lines?.find((element) => element.ID === parseInt(idElement, 10)).FORWARD ===
          0) {
            this.dataPlotPQ[phase][1] = this.dataPlotPQ[phase][1].map(function (x) {
              return x * (-1)
            })
          }
        }
        // Power L
        this.dataPlotPL = new Array(4)
        phase = 0
        for (phase = 0; phase <= 3; phase += 1) {
          const PL = dt.Results.GetLinePhaseLos(this.case, Frame1, Frame2, elementIndex, phase)
          this.dataPlotPL[phase] = [[...Array(Frame2).keys()], PL]
        }
        // Power UFC
        this.dataPlotPUFC = dt.Results.GetLineIu(this.case, Frame1, Frame2, elementIndex)
        // Power UFV
        this.dataPlotPUFV = dt.Results.GetBusVu(this.case, Frame1, Frame2, busIndex)
      } else if (typeElement === 'CGP' || typeElement === 'cgp') {
        // cgp
        const busIndexCGP = dt.Results.GetCGP2BusIndex(idElement)
        // LC Current
        let phase = 0
        this.dataPlot = new Array(4)
        phase = 0
        for (phase = 0; phase <= 3; phase += 1) {
          const LDCC = dt.Results.GetBusPhaseInj(this.case, Frame1, Frame2, busIndexCGP, phase)[0]
          this.dataPlot[phase] = [[...Array(Frame2).keys()], LDCC]
        }
        // LC Power
        this.dataPlotLCP = new Array(5)
        phase = 0
        for (phase = 0; phase <= 3; phase += 1) {
          const LDCP = dt.Results.GetBusPhaseInj(this.case, Frame1, Frame2, busIndexCGP, phase)[1]
          this.dataPlotLCP[phase] = [[...Array(Frame2).keys()], LDCP]
        }
        this.dataPlotLCP[4] = dt.Results.GetBusInj(this.case, Frame1, Frame2, busIndexCGP)[1]
        // Voltage
        this.dataPlotV = new Array(4)
        phase = 0
        for (phase = 0; phase <= 3; phase += 1) {
          const V = dt.Results.GetBusPhaseV(this.case, Frame1, Frame2, busIndexCGP, phase, 0)
          this.dataPlotV[phase] = [[...Array(Frame2).keys()], V]
        }
        // Current
        this.dataPlotI = new Array(4)
        phase = 0
        for (phase = 0; phase <= 3; phase += 1) {
          const I = dt.Results.GetBusPhaseInj(this.case, Frame1, Frame2, busIndexCGP, phase)[0]
          this.dataPlotI[phase] = [[...Array(Frame2).keys()], I]
        }
        // Power S
        this.dataPlotPS = new Array(4)
        phase = 0
        for (phase = 0; phase <= 3; phase += 1) {
          const PS = dt.Results.GetBusPhaseInj(this.case, Frame1, Frame2, busIndexCGP, phase)[3]
          this.dataPlotPS[phase] = [[...Array(Frame2).keys()], PS]
        }
        // Power P
        this.dataPlotPP = new Array(4)
        phase = 0
        for (phase = 0; phase <= 3; phase += 1) {
          const PP = dt.Results.GetBusPhaseInj(this.case, Frame1, Frame2, busIndexCGP, phase)[1]
          this.dataPlotPP[phase] = [[...Array(Frame2).keys()], PP]
        }
        // Power Q
        this.dataPlotPQ = new Array(4)
        phase = 0
        for (phase = 0; phase <= 3; phase += 1) {
          const PQ = dt.Results.GetBusPhaseInj(this.case, Frame1, Frame2, busIndexCGP, phase)[2]
          this.dataPlotPQ[phase] = [[...Array(Frame2).keys()], PQ]
        }
        // Power L
        this.dataPlotPL = null
        // Power UFC
        this.dataPlotPUFC = null
        // Power UFV
        this.dataPlotPUFV = null
      } else if (typeElement === 'Station' || typeElement === 'station') {
        // First time we will use the first Transformer (CT = Station)
        const IndexCT = dt.Results.GetCtIndex(this.idTransformer)
        // W1 -> refSide 0 , W2 -> refSide 1, W3 -> refSide 2
        let refSide = this.windings.find(w => w.ID === this.idWinding).index
        const WindingIndex = dt.Results.CT_winding_first[IndexCT] + refSide
        const busCTId = dt.Results.CT_bus[WindingIndex]

        // Load Duration Curve: Current
        let phase = 0
        const sign = refSide === 0 ? -1: 1
        this.dataPlot = new Array(4)
        phase = 0
        for (phase = 0; phase <= 3; phase += 1) {
          const LDCC = dt.Results.GetCtPhaseInj(this.case, Frame1, Frame2, IndexCT, phase, refSide)[0]
          this.dataPlot[phase] = [[...Array(Frame2).keys()], LDCC]
        }
        // Load Duration Curve: Power
        this.dataPlotLCP = new Array(5)
        phase = 0
        for (phase = 0; phase <= 3; phase += 1) {
          const LDCP = dt.Results.GetCtPhaseInj(this.case, Frame1, Frame2, IndexCT, phase, refSide)[1]
          this.dataPlotLCP[phase] = [[...Array(Frame2).keys()], LDCP]
          this.dataPlotLCP[phase][1] = this.dataPlotLCP[phase][1].map(value => value * sign)
        }
        this.dataPlotLCP[4] = dt.Results.GetCtInj(this.case, Frame1, Frame2, IndexCT, refSide, 0)[1]
        // Voltage
        this.dataPlotV = new Array(4)
        phase = 0
        for (phase = 0; phase <= 3; phase += 1) {
          const V = dt.Results.GetBusPhaseV(this.case, Frame1, Frame2, busCTId, phase, 0)
          this.dataPlotV[phase] = [[...Array(Frame2).keys()], V]
        }
        // Current
        this.dataPlotI = new Array(4)
        phase = 0
        for (phase = 0; phase <= 3; phase += 1) {
          const I = dt.Results.GetCtPhaseInj(this.case, Frame1, Frame2, IndexCT, phase, refSide)[0]
          this.dataPlotI[phase] = [[...Array(Frame2).keys()], I]
        }
        // Power S
        this.dataPlotPS = new Array(4)
        phase = 0
        for (phase = 0; phase <= 3; phase += 1) {
          const PS = dt.Results.GetCtPhaseInj(this.case, Frame1, Frame2, IndexCT, phase, refSide)[3]
          this.dataPlotPS[phase] = [[...Array(Frame2).keys()], PS]
        }
        // Power P
        this.dataPlotPP = new Array(4)
        phase = 0
        for (phase = 0; phase <= 3; phase += 1) {
          const PP = dt.Results.GetCtPhaseInj(this.case, Frame1, Frame2, IndexCT, phase, refSide)[1]
          this.dataPlotPP[phase] = [[...Array(Frame2).keys()], PP]
          this.dataPlotPP[phase][1] = this.dataPlotPP[phase][1].map(value => value * sign)
        }
        // Power Q
        this.dataPlotPQ = new Array(4)
        phase = 0
        for (phase = 0; phase <= 3; phase += 1) {
          const PQ = dt.Results.GetCtPhaseInj(this.case, Frame1, Frame2, IndexCT, phase, refSide)[2]
          this.dataPlotPQ[phase] = [[...Array(Frame2).keys()], PQ]
          this.dataPlotPQ[phase][1] = this.dataPlotPQ[phase][1].map(value => value * sign)
        }
        // Power L
        this.dataPlotPL = null
        // Power UFC
        this.dataPlotPUFC = null
        // Power UFV
        this.dataPlotPUFV = null
      }
    },

    async getKPIs (dt, dateFrame1, dateFrame2, level) {
      const casesNames = Object.keys(dt.cases)
      const cases = []
      for (let iter = 0; iter < casesNames.length; iter += 1) {
        cases[iter] = {}
        cases[iter].name = casesNames[iter]
        cases[iter].idx = dt.cases[casesNames[iter]].idx
      }
      /*
      cases.forEach((element, index) => {
        if (element.name === 'base') {
          cases[index].name = 'Base'
        }
      })
      */
      const simHeaders = []
      simHeaders[0] = {}
      simHeaders[0].text = 'KPI'
      simHeaders[0].align = 'start'
      simHeaders[0].sortable = true
      simHeaders[0].value = 'KPI'
      simHeaders[0].groupable = true
      simHeaders[1] = {}
      simHeaders[1].text = this.$t('unit')
      simHeaders[1].align = 'start'
      simHeaders[1].sortable = false
      simHeaders[1].value = 'Unit'
      simHeaders[1].groupable = false
      simHeaders[2] = {}
      simHeaders[2].text = this.$t('phase')
      simHeaders[2].align = 'start'
      simHeaders[2].sortable = true
      simHeaders[2].value = 'Phase'
      simHeaders[2].groupable = false
      cases.forEach((element) => {
        const temp = {}
        temp.text = element.name
        temp.align = 'start'
        temp.sortable = false
        temp.value = element.name
        temp.groupable = false
        simHeaders.push(temp)
      })
      this.simHeaders = simHeaders
      const Frame1 = dateFrame1
      const Frame2 = dateFrame2 - 1

      let caseSelected = this.currentCase
      // If you are creating a new case, it's still not simulated, so we take data from the reference
      if (this.creatingCase) {
        caseSelected = this.referenceCase
      }

      if (this.typeElement === 'Station') {
        this.simResults = await this.stationKpis(dt, cases, Frame1, Frame2, this.idElement, caseSelected)
      } else if (this.typeElement === 'Line') {
        this.simResults = await this.lineKpis(dt, cases, Frame1, Frame2, this.idElement, caseSelected)
      } else if (this.typeElement === 'CGP' || this.typeElement === 'connectionPoint') {
        this.simResults = await this.cgpKpis(dt, cases, Frame1, Frame2, this.idElement, caseSelected)
      }
    },

    getDevicesList () {
      const evObject = this.$store.state.DTChanges.EV
      const EVsets = []
      evObject?.CGP.forEach((element, index) => {
        const temp = {}
        temp.name = `EV Set ${index + 1}`
        temp.cgp = element
        temp.number = evObject.ndevs[index]
        temp.rcp = evObject.rcp[index]
        temp.c = evObject.c[index]
        temp.kmd = evObject.kmd[index]
        temp.stdkmd = evObject.stdkmd[index]
        temp.pf = evObject.pf[index]
        temp.ph = evObject.ph[index]
        if (temp.ph === 1) {
          temp.ph = 'A'
        } else if (temp.ph === 2) {
          temp.ph = 'B'
        } else if (temp.ph === 3) {
          temp.ph = 'C'
        } else if (temp.ph === 4) {
          temp.ph = 'ABC'
        }
        temp.start_min = evObject.start_min[index]
        temp.start_max = evObject.start_max[index]
        temp.isVisible = false
        EVsets.push(temp)
      })
      this.evList = EVsets
      const pvObject = this.$store.state.DTChanges.PV
      const PVsets = []
      pvObject?.CGP.forEach((element, index) => {
        const temp = {}
        temp.name = `PV Set ${index + 1}`
        temp.cgp = element
        temp.number = pvObject.ndevs[index]
        temp.ph = pvObject.ph[index]
        temp.pp = pvObject.pp[index]
        if (temp.ph === 1) {
          temp.ph = 'A'
        } else if (temp.ph === 2) {
          temp.ph = 'B'
        } else if (temp.ph === 3) {
          temp.ph = 'C'
        } else if (temp.ph === 4) {
          temp.ph = 'ABC'
        }
        temp.isVisible = false
        PVsets.push(temp)
      })
      this.pvList = PVsets
      const hpObject = this.$store.state.DTChanges.HP
      const HPsets = []
      hpObject?.CGP.forEach((element, index) => {
        const temp = {}
        temp.name = `Heat Pump Set ${index + 1}`
        temp.cgp = element
        temp.number = hpObject.ndevs[index]
        temp.ph = hpObject.ph[index]
        temp.pnom = hpObject.nhP[index]
        temp.pf = hpObject.pf[index]
        temp.cop = hpObject.cop[index]
        if (temp.ph === 1) {
          temp.ph = 'A'
        } else if (temp.ph === 2) {
          temp.ph = 'B'
        } else if (temp.ph === 3) {
          temp.ph = 'C'
        } else if (temp.ph === 4) {
          temp.ph = 'ABC'
        }
        temp.isVisible = false
        HPsets.push(temp)
      })
      this.hpList = HPsets
      this.loadScaleList = this.$store.state.DTChanges.LoadScale
    },

    changeMode (modeSelected) {
      this.mode = modeSelected
    },

    getMeasurementBus (busId, index) {
      const busIndex = this.digitalTwinResults.Results.GetBusIndex(busId)
      if (this.measurement === 'voltage') {
        if (this.submeasurement === 'min' || this.submeasurement === 'max' || this.submeasurement === 'avg') {
          try {
            return this.digitalTwinResults.Results
              .GetBusV(this.case, this.instant, this.instant,
                busIndex,
                this.submeasurementOptions[this.submeasurement][index])[0]
          } catch (error) {
            console.warn(busIndex)
          }
        } else if (this.submeasurement === 'phA' || this.submeasurement === 'phB' ||
            this.submeasurement === 'phC' || this.submeasurement === 'phN') {
          try {
            return this.digitalTwinResults.Results
              .GetBusPhaseV(this.case, this.instant, this.instant,
                busIndex,
                this.submeasurementOptions[this.submeasurement][index],
                this.submeasurementOptions.puOReal[index])[0]
          } catch (error) {
            console.warn(busIndex)
          }
        }
      } else {
        // CASE OF CURRENT, ACTIVE POWER, REACTIVE POWER AND LOSSES
        return -1
      }
      return -1
    },

    colorSegmentsSLD (lineId, childBus) {
      function getValue (lid, dst, powerFlowResults, lineMagnitudeFcn) {
        const lineIndex = powerFlowResults.GetLineIndex(lid)
        const lineDSTPFR = powerFlowResults.Bus_ID[powerFlowResults.Con_Dst[lineIndex]]
        let value = lineMagnitudeFcn(lid, 1)
        let direction
        if (value === 0) {
          direction = true
        } else {
          direction = (lineDSTPFR === dst)
          direction = ((value > 0) === direction)
        }
        value = Math.abs(value)
        return [value, direction]
      }
      // Function to calculate segment color
      const colorScale = this.getColorScale(this.numberOfColors,
        this.colorScaleColors, this.colorScaleLower, this.colorScaleMiddle, this.colorScaleUpper)
      const [segmentValue, flowDir] = getValue(lineId, childBus, this.digitalTwinResults.Results,
        this.getMeasurementValue)
      const segmentColor = colorScale(Math.abs(this.fixToLimits(segmentValue)))
      // TODO: MANAGE DIRECTION WHEN USING POWER FLOW
      // second output -> true: downstream, false: upstream, undefined: deactivated
      const direction = (this.measurement === 'activePower' || this.measurement === 'reactivePower') ? flowDir : undefined
      return [segmentColor, direction]
    },

    legendSegmentsSLD (lineId, _, linktype) {
      if (this.measurement !== 'voltage' && linktype === 'segment') {
        let value = this.roundToThree(parseFloat(this.getMeasurementValue(lineId, 1)))
        let valueAbsolute = this.roundToThree(parseFloat(this.getMeasurementValue(lineId, 0)))
        const fw = this.grid.Grid.lines?.find((element) => element.ID === parseInt(lineId, 10))
          .FORWARD === 1
        const units = this.getUnits(this.measurement, 1)
        const unitsAbsolute = this.getUnits(this.measurement, 0)
        if (this.measurement === 'current') {
          value = Math.abs(value)
          valueAbsolute = Math.abs(valueAbsolute)
          return [`${value} ${units}`, `${valueAbsolute} ${unitsAbsolute}`]
        }
        if (this.measurement === 'unbalance') {
          value = Math.abs(value)
          return [`${value} ${units}`]
        }
        if (this.measurement === 'losses') {
          valueAbsolute = Math.abs(valueAbsolute)
          return [`${valueAbsolute} ${unitsAbsolute}`]
        }
        if (!fw) {
          value = -value
          valueAbsolute = -valueAbsolute
        }
        if (this.measurement === 'activePower' || this.measurement === 'reactivePower') {
          return [`${value} ${units}`, `${valueAbsolute} ${unitsAbsolute}`]
        }
      }
      return ['']
    },

    legendBusSLD (busId, nodetype) {
      if (this.measurement === 'voltage' && nodetype === 'bus') {
        const value = parseFloat(Math.abs(this.getMeasurementBus(busId, 1))) <
          parseFloat(0.001)
          ? 0
          : this.roundToThree(parseFloat(this.getMeasurementBus(busId, 1)))
        const valueAbsolute = parseFloat(Math.abs(this.getMeasurementBus(busId, 0))) <
          parseFloat(0.001)
          ? 0
          : this.roundToThree(parseFloat(this.getMeasurementBus(busId, 0)))
        return [`${value} p.u.`, `${valueAbsolute} V`]
      }
      return [busId]
    },
    colorBusSLD (busId) {
      if (this.measurement === 'voltage') {
        const colorScale = this.getColorScale(this.numberOfColors,
          this.colorScaleColors, this.colorScaleLower, this.colorScaleMiddle, this.colorScaleUpper)
        const value = this.getMeasurementBus(busId, 1)
        const color = colorScale(Math.abs(this.fixToLimits(value)))
        return [color]
      }
      return '#ffff'
    },
    roundToTwo (num) {
      return +(`${Math.round(`${num}e+2`)}e-2`)
    },
    roundToThree (num) {
      return +(`${Math.round(`${num}e+3`)}e-3`)
    },
    fixToLimits (value) {
      if (value >= this.colorScaleUpper) {
        return this.colorScaleUpper
      }
      if (value <= this.colorScaleLower) {
        return this.colorScaleLower
      }
      return value
    },

    colorLines () {
      // Set the color line corresponding to the instant, the id, and the measurement
      let sign = ''
      this.networksDetailsWithHeatmapLines = window._?.cloneDeep(this.networksDetails)
      const colorScale = this.getColorScale(this.numberOfColors,
        this.colorScaleColors, this.colorScaleLower, this.colorScaleMiddle, this.colorScaleUpper)
      this.networksDetails?.forEach((voltageLevel, indexLevel) => {
        voltageLevel.networks.forEach((network, indexNetwork) => {
          network.lines.forEach((line, indexLine) => {
            const measurementValue = this.getMeasurementValue(line.ID, 1)
            const measurementAbsoluteValue = this.getMeasurementValue(line.ID, 0)
            const colorLine = colorScale(Math.abs(this.fixToLimits(measurementValue)))
            // Set sign of the results and direction of the animation if needed
            if (measurementValue > 0.00 || (measurementValue === 0 && line.FORWARD === true)) {
              this.networksDetailsWithHeatmapLines[indexLevel].networks[indexNetwork].lines[indexLine].positive = true
            } else {
              this.networksDetailsWithHeatmapLines[indexLevel].networks[indexNetwork].lines[indexLine].positive = false
            }
            if (((this.measurement === 'activePower' || this.measurement === 'reactivePower') && measurementValue < 0.00 && line.FORWARD === true) ||
              ((this.measurement === 'activePower' || this.measurement === 'reactivePower') && measurementValue > 0.00 && line.FORWARD === false)) {
              sign = '-'
            }
            this.networksDetailsWithHeatmapLines[indexLevel].networks[indexNetwork].lines[indexLine].color = colorLine
            // Set the tooltip for the line
            this.networksDetailsWithHeatmapLines[indexLevel].networks[indexNetwork].lines[indexLine].tooltip = sign +
                Math.abs(this.roundToThree(measurementValue))?.toString() +
                this.getUnits(this.measurement, 1)
            this.networksDetailsWithHeatmapLines[indexLevel].networks[indexNetwork].lines[indexLine].tooltipAbsolute = sign +
                Math.abs(this.roundToThree(measurementAbsoluteValue))?.toString() +
                this.getUnits(this.measurement, 0)
          })
        })
      })
      this.$store.dispatch('setElement', { path: 'DTProject.networksInfo', value: this.networksDetailsWithHeatmapLines })
    },

    resizePanel () {
      this.$root.$emit('resizePlotly')
    },
    updateStation (windingTransformer) {
      // When you select a Transformer's winding in toolbar
      this.idWinding = windingTransformer.ID
      this.idTransformer = windingTransformer.TransformerID

      this.updatePanel(this.frame1, this.frame2)
    }
  }
}
</script>

<style scoped>
.splitpanes__pane {
  /* overflow: auto; */
  height: 93vh;
  background-color: #272727;
}
.splitpanes {
  background-color: #000;
}
.switchMapView {
  z-index: 500;
  position: absolute;
  float: left;
  left: 45px;
}
.tophigh {
  top: 10px;
}
.toplow {
  top: 60px;
}
</style>
