<template>
  <v-app>
    <div class="full-height">
      <div v-if="showSetAreaForm">
        <ASDialog
          :source="source"
          :loading="loadingArea"
          @getArea="getArea"
          @getElement="getElement"
        />
      </div>
      <div v-else>
        <splitpanes
          class="default-theme"
          style="height: 100%; width: 100%"
        >
          <pane
            v-if="networks && !isLoadingHCM"
            style="position: relative; width: 100%"
          >
            <MapBase
              v-if="networks && !SLDView"
              :mode="mode"
              :enabled-context-menu="true"
              :stations="stations"
              :networks="networks"
              :isolated-lines="isolatedLines"
              :station-open-fuses="stationOpenFuses"
              :list-elements="listElements"
              :hosting-capacity-map="hcmView"
              :grid-bounds="gridBounds"
              :show-medium-voltage="true"
              :grid-inspection-map="true"
              :types-view-g-i="typesViewGI"
              :selected-type-view-map="selectedTypeViewMap"
              :hc-phase-index="phaseIndex"
              :show-hcm-warning="tooMuchStationsAvailable"
              @click="click"
              @changeMode="changeMode"
              @toggleNavbar="showBar = !showBar"
              @updateTypeViewMap="updateTypeViewMap"
              @onHCReservationClick="onHCReservationClick"
              @updateMapAfterHCReservation="updateMapAfterHCReservation"
              @updateMapAfterRemoveHCReservation="updateMapAfterRemoveHCReservation"
            />
            <template v-else-if="networks">
              <SingleLineDiagram
                v-if="!hcmView"
                :name="`sld-${network}`"
                :station-name="network"
                scrollable="true"
                :node-legend="nodeLabelFcn"
                :legend-segment-top="segmentLabelFcn"
                :devices="{}"
                :level="levelVoltage"
                @click="click"
              />
              <SingleLineDiagram
                v-else
                :name="`sld-${network}`"
                :station-name="network"
                scrollable="true"
                :level="levelVoltage"
                :devices="{}"
                :node-legend="nodeLabelFcn"
                :legend-segment-top="legendSegmentsSLD"
                :segment-color="colorSegmentsSLD"
                :bus-color="colorBusSLD"
                @click="click"
              />
            </template>
            <v-switch
              v-if="hcmView && HCManageResultsSolved.length"
              v-model="hcmWorstCaseView"
              class="switchMapView"
              :class="{ tophighHC: !showBar, toplowHC: showBar }"
              inset
              :color="'#f4c020'"
              :label="worstCaseText"
              @change="changeWorstCaseSwitch"
            />
            <HCMPhaseSelector
              v-if="hcmView && HCManageResultsSolved.length"
              :sld-view="SLDView"
              :top-high="!showBar"
              :phase-index="phaseIndex"
              @changePhaseIndex="changePhaseIndex"
            />
            <HideRightPanelBtn
              v-if="toggleButtonShow"
              :panel-show="panelShow"
              :toggle-panel-show="togglePanelShow"
            />
            <ColorScale
              v-if="hcmView && HCManageResultsSolved.length"
              :lower="colorScaleLower"
              :middle="colorScaleMiddle"
              :upper="colorScaleUpper"
              :colors="hcmColors"
              :prefix="'kW'"
              :color-scale-lower-min="colorScaleLowerMin"
              :color-scale-upper-max="colorScaleUpperMax"
              @changeUpperLimit="changeUpperLimit"
              @changeLowerLimit="changeLowerLimit"
              @setColorScale="setColorScale"
            />
            <TimeSlider
              v-if="
                hcmView &&
                  !hcmWorstCaseView &&
                  HCManageResultsSolved.length &&
                  hcmTime.StartTime
              "
              :steps="hcmTime.StepCount"
              :init="hcmTime.StartTime"
              :hosting-capacity-map="hcmView"
              :init-instant="instant"
              @changeInstant="changeInstant"
            />
            <v-switch
              v-show="showBar"
              v-model="SLDView"
              class="switchMapView"
              :class="{ tophigh: SLDView, toplow: showBar && !SLDView }"
              inset
              :color="'#f4c020'"
              :label="SLDView ? $t('map.map_view') : $t('map.sld')"
              :disabled="network === null || levelVoltage !== '400'"
            />
          </pane>
          <pane v-else-if="isLoadingHCM">
            <CMLoader
              :loading="loadingHCM.isVisible"
              :message="loadingHCM.message"
            />
          </pane>
          <pane v-else>
            <CMLoader
              :loading="loadingSelf.isVisible"
              :message="loadingSelf.message"
            />
          </pane>
          <pane
            v-if="panelShow"
            style="background-color: #232323"
            :size="30"
            :min-size="20"
            :max-size="60"
          >
            <GridInspectionPanel
              :id-element="idElement"
              :type-element="typeElement"
              :network="network"
              :station-info="stationInfo"
              :selected-type-view-map="selectedTypeViewMap"
              :show-plots="hcmView"
              :hcm-time="hcmTime"
              :element-h-c-m="elementHCM"
              :data-plot-h-c="dataPlotHC"
              :reservation-network="reservationNetwork"
              @updateMapAfterRemoveHCReservation="updateMapAfterRemoveHCReservation"
            />
          </pane>
        </splitpanes>
      </div>
    </div>
  </v-app>
</template>

<script>
// @ is an alias to /src
import { cloneDeep } from 'lodash'
import { mapState, mapGetters } from 'vuex'
import 'leaflet/dist/leaflet.css'
import { Splitpanes, Pane } from 'splitpanes'
import 'splitpanes/dist/splitpanes.css'
import SingleLineDiagram from '@/components/Map/SingleLineDiagram.vue'
import CMLoader from '@/components/Common/CMLoader.vue'
import ASDialog from '@/components/AreaSelector/ASDialog.vue'
import { getAreaMap } from '@/services/areaSelector'
import getAreaGridMixin from '@/mixins/getAreaGridMixin'
import HostingCapacityMap from '@/mixins/Heatmaps/HostingCapacityMap'
import getColorScale from '@/mixins/map/mapColorScaleMixin'
import HCReservationMixin from '@/mixins/Heatmaps/HCReservationMixin'
import MapBase from '@/components/Map/MapBase.vue'
import GridInspectionPanel from '@/components/GridInspection/GIPanel.vue'
import ColorScale from '@/components/Common/CMColorScale.vue'
import TimeSlider from '@/components/Common/CMTimeSlider.vue'
import HideRightPanelBtn from '@/components/Common/CMHideRightPanelBtn.vue'
import HCMPhaseSelector from '@/components/GridInspection/HostingCapacityMap/HCMPhaseSelector'
import clickElements from '@/mixins/map/mapElementSelectMixin'
import unclickElements from '@/mixins/map/mapElementUnselectMixin'
import { getNetworksObject } from '@/mixins/map'
import vuexMixin from '@/mixins/vuexMixin'
import mapRemoveTooltipMixin from '@/mixins/map/mapRemoveTooltipMixin'

export default {
  name: 'GridInspection',

  metaInfo: {
    title: 'Grid Inspection'
  },

  components: {
    MapBase,
    GridInspectionPanel,
    Splitpanes,
    Pane,
    SingleLineDiagram,
    CMLoader,
    ASDialog,
    HCMPhaseSelector,
    ColorScale,
    TimeSlider,
    HideRightPanelBtn
  },
  mixins: [
    getAreaGridMixin,
    HostingCapacityMap,
    getColorScale,
    HCReservationMixin,
    clickElements,
    unclickElements,
    getNetworksObject,
    vuexMixin,
    mapRemoveTooltipMixin
  ],
  data () {
    return {
      feedback: {},
      mode: 'network_mode',
      panelShow: false,
      idElement: null,
      typeElement: null,
      network: null,
      listElements: {
        stations: [],
        lines: [],
        fuses: [],
        connectionPoints: []
      },
      stations: null,
      networks: null,
      openFuses: null,
      isolatedLines: null,
      stationOpenFuses: [],
      host: this.$API_HOST,
      port: this.$API_PORT,
      SLDView: false,
      gridBounds: undefined,
      source: null,
      levelVoltage: null,
      showBar: true,
      loadingArea: false,
      networkResponse: null,
      HCMNetwork: null,
      selectedTypeViewMap: 'connectivity',
      hcmTime: {
        StepCount: 24,
        StartTime: null
      },
      phaseIndex: 3,
      isLoadingHCM: false,
      dataPlotHC: [],
      reservationNetwork: null,
      hcmWorstCaseView: true,
      toggleButtonShow: false,
      colorScaleLowerMin: 0,
      colorScaleUpperMax: 0,
      selectedElement: {
        level: null,
        network: null,
        elementId: null,
        elementType: null
      }
    }
  },

  computed: {
    ...mapGetters(['HCManageResultsSolved', 'CurrentMod']),
    ...mapState({
      showSetAreaForm: (state) => state.showSetAreaForm,
      areaGrid: (state) => state.areaGrid,
      HCManageResults: ({ hostingCapacity }) => hostingCapacity.HCManageResults,
      measurement: (state) => state.measurement.measurement,
      HCNetworksWithLines: ({ hostingCapacity }) => hostingCapacity.HCNetworksWithLines
    }),
    lowNetworksAvaliable () {
      if (!this.networks.length) {
        return false
      }
      const index = this.networks.findIndex((networks) => networks.level === '400')
      return index !== -1
    },
    tooMuchStationsAvailable () {
      // To not overload the internet navigator we must control that there aren't too much stations.
      const lowLevel = this.networks.find((networks) => networks.level === '400') // HCM only calculates from low level

      if (lowLevel.networks.length > 5000) {
        return true
      }
      return false
    },
    typesViewGI () {
      return [
        { id: 1, text: this.$t('grid_inspector.raw'), value: 'rawView', disabled: false },
        { id: 2, text: this.$t('grid_inspector.connectivity'), value: 'connectivity', disabled: false },
        {
          id: 3,
          text: this.$t('grid_inspector.hosting_capacity'),
          value: 'hostingCapacityMap',
          disabled: false
        },
        { id: 4, text: this.$t('grid_inspector.hcm_consumption'), value: 'consumption', disabled: !this.lowNetworksAvaliable || this.tooMuchStationsAvailable },
        { id: 5, text: this.$t('grid_inspector.hcm_generation'), value: 'generation', disabled: !this.lowNetworksAvaliable || this.tooMuchStationsAvailable }
      ]
    },
    loadingHCM () {
      return {
        isVisible: true,
        message: this.$t('hcm.loading_msg')
      }
    },
    loadingSelf () {
      return {
        isVisible: true,
        message: this.$t('grid_inspector.loading_msg')
      }
    },
    loadingSelfArea () {
      return {
        isVisible: true,
        message: this.$t('grid_set.getting_grid_msg')
      }
    },
    worstCaseText () {
      return this.$t('hcm.worst_case_txt')
    },
    stationInfo () {
      let stInf
      this.networks.forEach((networks) => {
        const network = networks.networks.find(
          (x) => x.network.NAME === this.network
        )
        if (network) {
          stInf = network
        }
      })
      return stInf
    },
    hcmView () {
      return (
        this.selectedTypeViewMap === 'consumption' ||
        this.selectedTypeViewMap === 'generation'
      )
    },
    hcmMode () {
      if (this.selectedTypeViewMap === 'consumption') {
        return 0
      }
      return this.selectedTypeViewMap === 'generation' ? 1 : null
    }
  },

  async mounted () {
    this.loadingArea = false
    this.setVuexElement({
      path: 'showSetAreaForm',
      value: false
    })
    if (
      this.$sessionStorage.zoneId !== null &&
      this.$sessionStorage.zoneId !== 'null'
    ) {
      this.getArea()
    } else if (this.$sessionStorage.gridElement !== null) {
      this.getElement()
    } else {
      this.setVuexElement({
        path: 'showSetAreaForm',
        value: true
      })
    }
    this.source = await getAreaMap()
    if (this.hcmView) {
      const tempNetworks = this.networks
      this.updateNetworkColor(this.notAvailableColor, tempNetworks) // Prepare map to receive HCM results
      await this.getDataByHCType()
      this.networks = tempNetworks
    }
  },

  methods: {
    togglePanelShow (value) {
      this.panelShow = value
    },
    async click (level, network, elementId, elementType, isSearchElement = false) {
      this.levelVoltage = level
      this.toggleButtonShow = true
      this.panelShow = true
      if (this.SLDView) {
        this.mode = 'element_mode'
      }
      if (this.mode === 'element_mode') {
        this.idElement = elementId.toString()
        this.typeElement = elementType
        if (elementType === 'Line') {
          this.typeElement = 'line'
        } else if (elementType === 'connectionPoint') {
          this.typeElement = 'connectionPoint'
        } else if (elementType === 'Station') {
          this.typeElement = 'station'
        } else if (elementType === 'Fuse') {
          this.typeElement = 'fuse'
        }
      } else if (elementType === 'fuse' && !network) {
        this.typeElement = 'fuse'
        this.idElement = elementId.toString()
      } else {
        this.typeElement = 'network'
        this.idElement = network
      }
      this.network = network
      if (isSearchElement) {
        this.clickElements(
          network,
          elementId,
          elementType,
          false,
          this.hostingCapacityMap,
          this.multiselect
        )
      }
      this.selectedElement = { level, network, elementId, elementType }
      if (this.dataPlotHC.length) {
        this.dataPlotHC = []
      }
      if (this.hcmView && this.lowNetworksAvaliable && (elementType === 'line' || elementType === 'Line' || elementType === 'connectionPoint')) {
        const lowNetworksIndex = this.networks.findIndex((n) => n.level === '400')
        const elementNetwork = this.networks[lowNetworksIndex].networks.find((n) => n.network.NAME === network)
        if (elementNetwork) {
          this.dataPlotHC = await this.getHCPlotData(network, elementId, elementType, elementNetwork)
        }
      }
    },
    changeMode (modeSelected) {
      this.mode = modeSelected
      if (
        this.selectedElement.elementType &&
        this.selectedElement.elementType !== 'network'
      ) {
        this.click(
          this.selectedElement.level,
          this.selectedElement.network,
          this.selectedElement.elementId,
          this.selectedElement.elementType,
          true
        )
      }
      if (this.selectedElement.elementType === 'network') {
        this.selectedElement = {
          level: null,
          network: null,
          elementId: null,
          elementType: null
        }
        this.unselectElements()
        this.togglePanelShow(false)
        this.idElement = null
      }
    },
    showMap () {
      this.panelShow = false
      this.setVuexElement({
        path: 'showSetAreaForm',
        value: false
      })
    },

    nodeLabelFcn (id) {
      return [id]
    },
    segmentLabelFcn (id) {
      return [id]
    },
    setBounds () {
      this.gridBounds = {
        Xmax: parseFloat(sessionStorage.Xmax),
        Xmin: parseFloat(sessionStorage.Xmin),
        Ymax: parseFloat(sessionStorage.Ymax),
        Ymin: parseFloat(sessionStorage.Ymin)
      }
    },
    async getArea () {
      this.loadingArea = true
      this.areaResponse = await this.getAreaGrid(this.drawMap)
      this.setVuexElement({
        path: 'showSetAreaForm',
        value: false
      })
      this.setInitHCValues()
      this.loadingArea = false
    },
    async getElement () {
      this.loadingArea = true
      this.areaResponse = await this.getElementGrid(this.drawMap)
      this.setVuexElement({
        path: 'showSetAreaForm',
        value: false
      })
      this.setInitHCValues()
      this.loadingArea = false
    },

    setInitHCValues () {
      if (this.hcmView) {
        this.selectedTypeViewMap = 'connectivity'
        if (this.instant > 0) {
          this.setVuexElement({ path: 'instant', value: 0 })
        }
        if (!this.hcmWorstCaseView) {
          this.hcmWorstCaseView = true
        }
      }
    },

    drawMap (response) {
      this.setBounds()

      const networkObject = this.getNetworksObject(response)

      this.networks = networkObject.network
      this.listElements = networkObject.list

      this.setVuexElement({
        path: 'hostingCapacity.HCNetworksWithLines', value: this.networks
      })
      this.listElements = networkObject.list

      this.$emit('networkReady')
      this.showMap()
    },
    updateTypeViewMap (item) {
      this.selectedTypeViewMap = item.value
      const colorIndex = 0
      const tempNetworks = this.networks

      if (this.hcmView) {
        this.updateNetworkColor(this.notAvailableColor, tempNetworks) // Prepare map to receive HCM results
        this.getDataByHCType()
      } else {
        if (this.selectedTypeViewMap === 'rawView') {
          this.updateNetworkColor('#ffffff', tempNetworks)
          this.removeTooltipPane()
        } else if (this.selectedTypeViewMap === 'connectivity') {
          this.updateNetworkColor('#cccccc', tempNetworks, colorIndex, true)
          this.removeTooltipPane()
        }
        if (this.HCManageResults.length) {
          this.resetHCStoreValues()
          this.hcmWorstCaseView = false
        }
      }
      this.networks = tempNetworks
    },

    // Hosting Capacity Map methods
    updateNetworkColor (color, tempNetwork = [], colorIndex = undefined, isConnectivity = false) {
      tempNetwork.forEach((voltageLevel, indexLevel) => {
        voltageLevel.networks.forEach((network, index) => {
          if (isConnectivity) {
            const colorIndexInc = ++colorIndex
            colorIndex =
              colorIndex === this.areaStationsColors.length - 1
                ? 0
                : colorIndexInc
            tempNetwork[indexLevel].networks[index].color = this.areaStationsColors[colorIndex]
          } else { // Prepare map to receive HCM results
            tempNetwork[indexLevel].networks[index].color = color
            if (this.hcmView) {
              tempNetwork[indexLevel].networks[index].tooltipText = this.notAvailableText
              tempNetwork[indexLevel].networks[index].notAvailableHC = true
            }
          }
        })
      })
      this.setVuexElement({ path: 'hostingCapacity.HCNetworksWithLines', value: tempNetwork })
    },
    resetHCStoreValues () {
      [
        'HCManageResults',
        'HCReservations'
      ].forEach((element) => {
        this.setVuexElement({
          path: 'hostingCapacity.' + element,
          value: [],
          root: 'hostingCapacity'
        })
      })
    },
    async getDataByHCType () {
      this.panelShow = false
      this.isLoadingHCM = true
      this.networks = cloneDeep(this.HCNetworksWithLines)
      let networksWithHCMInfo
      const lowNetworksIndex = this.networks.findIndex(
        (networkElement) => networkElement.level === '400'
      )

      if (this.HCManageResults.length) {
        await this.updateInstantHCM()
      } else {
        networksWithHCMInfo = await this.getNetworksInfoHCM(lowNetworksIndex)
      }

      if (this.HCManageResultsSolved.length) {
        if (!this.HCReservations.length) {
          await this.getAllReservations()
        }
        this.setHCMTimeSliderDate()
        this.setColorScaleLimits()
      }
      if (networksWithHCMInfo?.length || this.HCManageResults?.length) {
        this.colorHCMap()
      } else {
        this.selectedTypeViewMap = 'connectivity'
      }
      this.isLoadingHCM = false
    },
    async updateInstantHCM () {
      const manageResults = await this.updateUnsolvedHCStations()
      for await (const hcManage of this.HCManageResultsSolved) {
        const index = manageResults.findIndex((el) => el === hcManage)
        if (hcManage.HCMResults) {
          const connPoints = hcManage.connPoints || []
          const worstDay = hcManage.infoHCM[`worst_day_${this.selectedTypeViewMap}`]
          manageResults[index].connPoints = await this.setConnPoints(hcManage.network, hcManage.HCMResults, worstDay, connPoints)
        }
      }
      this.setVuexElement({
        path: 'hostingCapacity.HCManageResults',
        value: manageResults
      })
    },
    async updateHCM (isUpdLimits = false) {
      this.networks = cloneDeep(this.HCNetworksWithLines)
      await this.updateInstantHCM()
      if (!isUpdLimits) {
        this.setColorScaleLimits()
      }
      this.colorHCMap()
    },
    getTimeForTooltip (stationWorstDay, instant) {
      const initData = new Date()
      let timeInit = Date.parse(stationWorstDay)
      initData.setTime(timeInit)
      const lagInit = initData.getTimezoneOffset()
      timeInit += lagInit * 60 * 1000

      const dateData = new Date()
      dateData.setTime(timeInit + instant * (60 * 60 * 1000))
      return `${dateData.getFullYear()}-${(
        '0' +
        (dateData.getMonth() + 1)
      ).slice(-2)}-${('0' + dateData.getDate()).slice(-2)} ${(
        '0' + dateData.getHours()
      ).slice(-2)}:${('0' + dateData.getMinutes()).slice(-2)}`
    },
    async getHCPlotData (network, elementId, elementType, elementNetwork) { // Get plot data per network
      let plotData = []
      const isPlotAvailable = this.isElementPlotAvailable(network, elementId, elementType, elementNetwork)
      if (isPlotAvailable && (elementType === 'line'|| elementType === 'Line' || elementType === 'connectionPoint')) { // && (elementType === 'line'|| elementType === 'Line' || elementType === 'connectionPoint')
        const haveReservations = this.HCReservations.find((el) => String(el.cgp) === elementId)
        if (elementType === 'line' || elementType === 'Line' || !haveReservations) { // Lines do not support reservations
          this.reservationNetwork = null
        }
        let elementHCMInfo = null
        if (this.HCManageResultsSolved.length) {
          elementHCMInfo = this.HCManageResultsSolved.find(
            (el) => el.network === network
          )
          if (elementHCMInfo?.HCMResults) { // The element has hosting capacity value
            plotData = await this.setHCPlotData(network, elementId, elementType, elementHCMInfo)
          }
        }
      }
      return plotData
    },
    async setHCPlotData (network, elementId, elementType, elementHCMInfo) {
      const modifiedElementType = elementType === 'connectionPoint' ? 'connPoint' : elementType
      const elementHCM = await this.getElementHCM(
        network,
        elementHCMInfo.HCMResults,
        modifiedElementType,
        elementId,
        this.hcmMode
      )
      if (elementHCM) {
        const plotData = []
        for (let i = 0; i < 4; i++) {
          const el = Array.from(this.elementHCM[i])
          plotData.push(el)
        }
        return plotData
      }
      return []
    },
    isElementPlotAvailable (network, elementId, elementType, elementNetwork) {
      const isUnavailable = this.checkReservations.find((el) => el.network === network) // Check Hcm is not being recalculated
      const isElementHCAvailable = this.isElementHCAvailable(elementId, elementType, elementNetwork)
      return isElementHCAvailable && !isUnavailable
    },
    isElementHCAvailable (elementId, elementType, elementNetwork) {
      const type = {
        line: 'lines',
        connectionPoint: 'connection_points',
        Line: 'lines'
      }
      const el = elementNetwork[type[elementType]].find((element) => element.ID === elementId)
      return !el.notAvailableHC && el.notAvailableHC !== undefined
    },
    changeWorstCaseSwitch () {
      if (this.hcmView && this.HCManageResults.length) {
        // Set the initial instant when disabling the worst case scenario
        if (this.instant > 0 && !this.hcmWorstCaseView) {
          this.setVuexElement({ path: 'instant', value: 0 })
        }
        this.updateHCM()
      }
    },
    async changePhaseIndex (value) {
      this.phaseIndex = value
      if (this.HCManageResults.length) {
        this.updateHCM()
      }
    },
    setHCMTimeSliderDate () {
      const worstDay =
        this.HCManageResultsSolved[0].infoHCM[
          `worst_day_${this.selectedTypeViewMap}`
        ]
      let dateParse = Date.parse(worstDay)
      const initData = new Date()
      initData.setTime(dateParse)
      const lagInit = initData.getTimezoneOffset()
      dateParse += lagInit * 60 * 1000
      this.hcmTime.StartTime = dateParse
    },
    setColorScaleLimits () {
      this.colorScaleLower = this.setLowerColorScale()
      this.colorScaleUpper = this.setUpperColorScale()
      this.colorScaleMiddle = (this.colorScaleLower + this.colorScaleUpper) / 2
      this.colorScaleLowerMin = this.colorScaleLower
      this.colorScaleUpperMax = this.colorScaleUpper
    },
    setLowerColorScale () {
      const listMin = []
      this.HCManageResultsSolved.forEach((element) => {
        if (element.connPoints) {
          listMin.push(this.findMinValue(element.connPoints))
        }
      })
      return Math.min(...listMin)
    },
    setUpperColorScale () {
      const listMax = []
      this.HCManageResultsSolved.forEach((element) => {
        if (element.connPoints) {
          listMax.push(this.findMaxValue(element.connPoints))
        }
      })
      return Math.max(...listMax)
    },
    findMinValue (connPoints) {
      let minValue = connPoints[0].value
      Object.entries(connPoints).forEach((cp) => {
        if (cp[1].value < minValue) {
          minValue = cp[1].value
        }
      })
      return minValue
    },
    findMaxValue (connPoints) {
      let maxValue = connPoints[0].value
      Object.entries(connPoints).forEach((cp) => {
        if (cp[1].value > maxValue) {
          maxValue = cp[1].value
        }
      })
      return maxValue
    },
    onHCReservationClick (network) {
      this.reservationNetwork = network
    },
    async updateMapAfterHCReservation (networkName) {
      this.networks = cloneDeep(this.HCNetworksWithLines)

      const lowNetworksIndex = this.networks.findIndex(
        (networkElement) => networkElement.level === '400'
      )
      const networkIndex = this.networks[lowNetworksIndex].networks.findIndex(
        (network) => network.network.NAME === networkName
      )
      const updatedNetwork = this.setWhiteColorForStation(
        this.networks[lowNetworksIndex],
        networkIndex
      )
      this.networks.splice(lowNetworksIndex, 1, updatedNetwork)
      this.setVuexElement({ path: 'hostingCapacity.HCNetworksWithLines', value: this.networks })
      if (this.dataPlotHC.length) {
        this.dataPlotHC = []
      }
      await this.updateReservations(this.networks, lowNetworksIndex, networkIndex)
    },
    async updateMapAfterRemoveHCReservation (removedReservation) {
      this.networks = cloneDeep(this.HCNetworksWithLines)
      const lowNetworksIndex = this.networks.findIndex(
        (networkElement) => networkElement.level === '400'
      )
      const networkIndex = this.networks[lowNetworksIndex].networks.findIndex(
        (network) => network.network.NAME === removedReservation.network
      )
      const updatedNetwork = this.setWhiteColorForStation(
        this.networks[lowNetworksIndex],
        networkIndex
      )
      if (this.dataPlotHC.length) {
        this.dataPlotHC = []
      }
      this.networks.splice(lowNetworksIndex, 1, updatedNetwork)
      this.setVuexElement({ path: 'hostingCapacity.HCNetworksWithLines', value: this.networks })

      const updatedHCMap = await this.checkUpdatedHCMap(
        lowNetworksIndex,
        networkIndex,
        removedReservation.station,
        removedReservation.network
      )
      if (updatedHCMap[1]) {
        this.setVuexElement({
          path: 'hostingCapacity.checkReservations',
          value: this.checkReservations.filter((el) => el.id !== removedReservation.id)
        })
        const newNetworks = this.networks[lowNetworksIndex]
        newNetworks.networks = updatedHCMap[1]
        this.networks.splice(lowNetworksIndex, 1, newNetworks)
        this.setVuexElement({ path: 'hostingCapacity.HCNetworksWithLines', value: this.networks })
      }
    },

    colorSegmentsSLD (lineId, childBus) { // color of segments
      const lowNetworksIndex = this.HCNetworksWithLines.findIndex(
        (network) => network.level === '400'
      )

      const indexNetwork = this.HCNetworksWithLines[lowNetworksIndex].networks.findIndex(network => network.network.NAME === this.selectedElement.network)
      const indexLine = this.HCNetworksWithLines[lowNetworksIndex].networks[indexNetwork].lines.findIndex(line => line.ID === lineId)
      const segmentColor = this.HCNetworksWithLines[lowNetworksIndex].networks[indexNetwork].lines[indexLine]?.color

      return [segmentColor || '#ffff', undefined]
    },

    legendSegmentsSLD (lineId, lineName, linktype) { // segment line legends
      const lowNetworksIndex = this.HCNetworksWithLines.findIndex(
        (network) => network.level === '400'
      )
      const indexNetwork = this.HCNetworksWithLines[lowNetworksIndex].networks.findIndex(network => network.network.NAME === this.selectedElement.network)
      const indexLine = this.HCNetworksWithLines[lowNetworksIndex].networks[indexNetwork].lines.findIndex(line => line.ID === lineId)
      const line = this.HCNetworksWithLines[lowNetworksIndex].networks[indexNetwork].lines[indexLine]

      return [line?.tooltip ? line?.tooltip : '', line?.tooltipAbsolute ? line?.tooltipAbsolute : '']
    },

    colorBusSLD (busId) {
      return '#ffff'
    }
  }
}
</script>

<style scoped>
.splitpanes__pane {
  overflow: auto;
  height: 93vh;
  background-color: #272727;
}
.splitpanes {
  background-color: #272727;
}

.switchMapView {
  z-index: 500;
  position: absolute;
  float: left;
  left: 10px;
}
.tophigh {
  top: 10px;
}
.tophighHC {
  top: 50px;
}
.toplowHC {
  top: 100px;
}
.toplow {
  top: 60px;
}
</style>
<style>
.splitpanes.default-theme .splitpanes__splitter {
  background-color: #333;
}

.splitpanes.default-theme .splitpanes__splitter:before,
.splitpanes.default-theme .splitpanes__splitter:after {
  background-color: #ccc;
}

.splitpanes.default-theme .splitpanes__splitter:hover:before,
.splitpanes.default-theme .splitpanes__splitter:hover:after {
  background-color: #777;
}
</style>
